import { useContext, useState, useRef, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../DataStore';

import ComboCounter from './ComboCounter';

import * as constants from '../exports/constants';

import '../../styles/recorder/ComboRecorder.scss';

const ComboRecorder = forwardRef((props, ref) => {
	const { store, dispatch } = useContext(Context);
	const [loadedOrigin, setLoadedOrigin] = useState(false);

	const refs = {
		localCamStream: useRef(null),
		localScreenStream: useRef(null),
		localOverlayStream: useRef(null),
		videoCam: useRef(null),
		videoScreen: useRef(null),
		videoOverlay: useRef(null),
		canvasElement: useRef(null),
		intervalId: useRef(null),
		rafId: useRef(null),
		mediaRecorder: useRef(null),
		audioContext: useRef(null),
		audioDestination: useRef(null),
		recording: useRef(false),
		recordButton: useRef(null),
		stopButton: useRef(null),
		cameraOffButton: useRef(null),
		timer: useRef(null),
		maxTimer: useRef(null),
		comboCounter: useRef(null)
	};

	let recordedBlobBuffer = [];
	let audioTracks = [];

	// Controls Object
	const controls = [
		{
			ref: refs.recordButton,
			className: 'playerButton',
			disabled: false,
			onClick: () => startRecordingCombo(),
			text: 'Record'
		},
		{
			ref: refs.stopButton,
			className: 'playerButton',
			disabled: false,
			onClick: () => stopRecordingCombo(),
			text: 'Stop'
		},
		{
			ref: refs.cameraOffButton,
			className: 'playerButton',
			disabled: false,
			onClick: () => stopComboCamera(),
			text: 'Camera Off'
		}
	]

	const requestVideoFrame = function (callback) {
		return window.requestAnimationFrame(callback);
	};

	const requestVideoFrameFallback = function (callback) {
		return window.setTimeout(function () {
			callback(Date.now());
		}, 1000 / 60);
	};

	const cancelVideoFrame = function (id) {
		window.cancelAnimationFrame(id);
	};

	const cancelVideoFrameFallback = function (id) {
		clearTimeout(id);
	};

	const resetStreams = () => {
		[
			...(refs.localCamStream.current ? refs.localCamStream.current.getTracks() : []),
			...(refs.localScreenStream.current ? refs.localScreenStream.current.getTracks() : []),
			...(refs.localOverlayStream.current ? refs.localOverlayStream.current.getTracks() : [])
		].map((track) => track.stop());
		refs.localCamStream.current = null;
		refs.localScreenStream.current = null;
		refs.localOverlayStream.current = null;

		cancelVideoFrame(refs.rafId.current);
		cancelVideoFrameFallback(refs.intervalId.current);

		document.removeEventListener('visibilitychange', visibilityChangeHandler);
	}

	const makeComposite = useCallback(async () => {
		if (refs.canvasElement.current) {
			const canvasCtx = refs.canvasElement.current.getContext('2d', { willReadFrequently: true });

			if (refs.videoCam.current && refs.videoScreen.current) {
				const screenWidth = refs.videoScreen.current.videoWidth;
				const screenHeight = refs.videoScreen.current.videoHeight;
				const camOriginalWidth = refs.videoCam.current.videoWidth;
				const camOriginalHeight = refs.videoCam.current.videoHeight;

				const circleDiameter = screenHeight * 0.30;
				const circleRadius = circleDiameter / 2;

				const aspectRatio = camOriginalWidth / camOriginalHeight;

				let cropWidth, cropHeight;
				if (aspectRatio > 1) {
					cropHeight = camOriginalHeight;
					cropWidth = cropHeight * aspectRatio;
				} else {
					cropWidth = camOriginalWidth;
					cropHeight = cropWidth / aspectRatio;
				}

				const scale = Math.max(circleDiameter / cropWidth, circleDiameter / cropHeight);
				const camWidth = cropWidth * scale;
				const camHeight = cropHeight * scale;

				const offsetX = (camWidth - circleDiameter) / 2;
				const offsetY = (camHeight - circleDiameter) / 2;

				const camX = 10 + circleRadius;
				const camY = screenHeight - circleDiameter - 10 + circleRadius;

				refs.canvasElement.current.setAttribute('width', `${screenWidth}px`);
				refs.canvasElement.current.setAttribute('height', `${screenHeight}px`);
				canvasCtx.clearRect(0, 0, screenWidth, screenHeight);

				// Draw the screen share feed
				canvasCtx.drawImage(refs.videoScreen.current, 0, 0, screenWidth, screenHeight);

				// Draw the circle crop for the webcam feed
				canvasCtx.save();
				canvasCtx.beginPath();
				canvasCtx.arc(camX, camY, circleRadius, 0, 2 * Math.PI);
				canvasCtx.clip();

				// Flip the webcam feed horizontally
				canvasCtx.scale(-1, 1);
				canvasCtx.drawImage(
					refs.videoCam.current,
					(camOriginalWidth - cropWidth) / 2, (camOriginalHeight - cropHeight) / 2, cropWidth, cropHeight,
					-(camX + circleRadius + offsetX), camY - circleRadius - offsetY, camWidth, camHeight
				);

				canvasCtx.restore();

				if (document.visibilityState === 'visible') {
					refs.rafId.current = requestVideoFrame(makeComposite);
				} else {
					refs.intervalId.current = requestVideoFrameFallback(makeComposite);
				}
			}
		}
	}, [refs]);

	const visibilityChangeHandler = useCallback(() => {
		if (document.visibilityState === 'visible') {
			cancelVideoFrameFallback(refs.intervalId.current);
			makeComposite();
		} else {
			cancelVideoFrame(refs.rafId.current);
			refs.intervalId.current = requestVideoFrameFallback(makeComposite);
		}
	}, [makeComposite, refs]);

	const countdownRecorder = () => {
		// Create a new audio object
		const countdownSound = new Audio(`${process.env.REACT_APP_CF_APP_ENDPOINT}mp3/ping.mp3`);

		const countdownAndBeep = (count) => {
			//setCountdown(count);
			countdownSound.play();
		}

		countdownAndBeep(3);

		setTimeout(() => {
			countdownAndBeep(2);
			setTimeout(() => {
				countdownAndBeep(1);
				setTimeout(() => {
					if (!store.status.modal.data.magicAI) {
						refs.maxTimer.current = setTimeout(() => {
							clearInterval(refs.timer.current);
							stopRecordingCombo();
						}, 60000 * 1.0);
					}

					startRecordingCombo();
				}, 1000);
			}, 1000);
		}, 1000);
	}

	const startWebcam = useCallback(async () => {
		buttonManager([true, true, false]);

		try {
			refs.localCamStream.current = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
			refs.localScreenStream.current = await navigator.mediaDevices.getDisplayMedia({
				video: {
					cursor: "always",
					preferCurrentTab: true
				},
				audio: true
			});

			if (refs.localCamStream.current) refs.videoCam.current.srcObject = new MediaStream(refs.localCamStream.current.getTracks());
			if (refs.localScreenStream.current) refs.videoScreen.current.srcObject = new MediaStream(refs.localScreenStream.current.getTracks());

			refs.localScreenStream.current.getVideoTracks()[0].addEventListener('ended', () => {
				stopRecordingCombo();
			});

			document.addEventListener('visibilitychange', visibilityChangeHandler);

			navigator.mediaDevices.enumerateDevices().then(async () => {
				await makeComposite();
				refs.audioContext.current = new AudioContext();
				refs.audioDestination.current = refs.audioContext.current.createMediaStreamDestination();
				let fullVideoStream = refs.canvasElement.current.captureStream();
				let existingAudioStreams = [
					...(refs.localCamStream.current ? refs.localCamStream.current.getAudioTracks() : []),
					...(refs.localScreenStream.current ? refs.localScreenStream.current.getAudioTracks() : [])
				];

				// Add microphone stream
				const microphoneStream = await navigator.mediaDevices.getUserMedia({ audio: true });
				existingAudioStreams.push(...microphoneStream.getAudioTracks());

				audioTracks.push(
					refs.audioContext.current.createMediaStreamSource(
						new MediaStream([existingAudioStreams[0]])
					)
				);
				if (existingAudioStreams.length > 1) {
					audioTracks.push(
						refs.audioContext.current.createMediaStreamSource(
							new MediaStream([existingAudioStreams[1]])
						)
					);
				}
				audioTracks.map((track) => track.connect(refs.audioDestination.current));

				refs.localOverlayStream.current = new MediaStream([...fullVideoStream.getVideoTracks()]);
				let fullOverlayStream = new MediaStream([
					...fullVideoStream.getVideoTracks(),
					...refs.audioDestination.current.stream.getTracks()
				]);

				if (refs.localOverlayStream.current) {
					refs.videoOverlay.current.srcObject = new MediaStream(refs.localOverlayStream.current.getTracks());

					refs.mediaRecorder.current = new MediaRecorder(fullOverlayStream, {
						mimeType: constants.utils.isSafari() ? 'video/mp4; codecs=avc1' : 'video/webm; codecs=avc1'
					});
					refs.mediaRecorder.current.ondataavailable = (event) => {
						if (event.data.size > 0) {
							recordedBlobBuffer.push(event.data);
						}
					};

					refs.mediaRecorder.current.onstop = () => {
						const doneSound = new Audio(`${process.env.REACT_APP_CF_APP_ENDPOINT}mp3/done.mp3`);
						doneSound.play();

						resetStreams();

						props.setRecordedBlobs(recordedBlobBuffer);

					}

					refs.videoOverlay.current.volume = 0;
					refs.videoCam.current.volume = 0;
					refs.videoScreen.current.volume = 0;
				}

				// Callback after the webcam and screen share streams have been loaded
				props.setLoading(false);
				props.setCameraStarted(true);

				buttonManager([false, true, false]);

				countdownRecorder();

				const statusCopy = {
					...store.status,
					recorder: {
						...store.status.recorder,
						started: true,
					}
				}

				dispatch({
					type: 'status',
					data: statusCopy
				});
			});
		} catch (error) {
			stopComboCamera();
		}
	}, [makeComposite, visibilityChangeHandler, refs, store, dispatch, props]);

	const buttonManager = (buttonStates) => {
		refs.recordButton.current.disabled = buttonStates[0];
		refs.stopButton.current.disabled = buttonStates[1];
		refs.cameraOffButton.current.disabled = buttonStates[2];
	}

	const startRecordingCombo = async () => {
		buttonManager([true, false, true]);
		refs.comboCounter.current.startCountDown();
		refs.mediaRecorder.current.start();
	};

	const stopRecordingCombo = async () => {
		buttonManager([true, true, false]);
		refs.comboCounter.current.stopCountDown();
		refs.mediaRecorder.current.stop();
	};

	const stopComboCamera = async () => {
		resetStreams();
		if (refs.comboCounter.current) {
			refs.comboCounter.current.stopCountDown();
		}
		props.resetComboRecorder();
	}

	useImperativeHandle(ref, () => ({
		startWebcam,
		startRecordingCombo,
		stopRecordingCombo
	}));

	useEffect(() => {
		if (!loadedOrigin) {
			setLoadedOrigin(true);
			startWebcam();
		}
		return () => {
			cancelVideoFrame(refs.rafId.current);
			cancelVideoFrameFallback(refs.intervalId.current);
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, [loadedOrigin, startWebcam, refs.intervalId, refs.rafId, visibilityChangeHandler]);

	return (
		<div className="ComboRecorder">
			<div className="hiddenVideos">
				<video className="videoCam hidden" ref={refs.videoCam} autoPlay playsInline />
				<video className="videoScreen hidden" ref={refs.videoScreen} autoPlay playsInline />
				<video className="preview" ref={refs.videoOverlay} autoPlay playsInline />
			</div>
			<canvas className="hidden" ref={refs.canvasElement} />

			<ComboCounter
				visible={!props.mediaRecorded}
				ref={refs.comboCounter} />

			<div className="buttonControls">
				{controls.map((control, i) => (
					<div
						className="buttonWrapper"
						key={i}>
						<button
							ref={control.ref}
							className={control.className}
							disabled={control.disabled}
							onClick={control.onClick}>
							{props.setBuildButton(control.text)}
						</button>
					</div>
				))}

			</div>
		</div>
	);
});

export default ComboRecorder;
