import { useContext, useState, useRef, useCallback, useEffect } from 'react';
import { Context } from '../../DataStore';
import { useLocation, useHistory } from 'react-router-dom';

import Loading from '../common/Loading';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/composite/Menu.scss';

const Arrow = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/arrow.svg`;
const EmptyCommunity = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/empty-community.svg`;
const EmptyFolders = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/folder.svg`;
const FolderIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/empty-folder.svg`;
const EditFolders = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/edit.svg`;
const EditFoldersSelected = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/edit-selected.svg`;
const DeleteIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/delete.svg`;
const DeleteIconWhite = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/delete-white.svg`;
const CopyFolders = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/copy-folders.svg`;
const CopyFoldersIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/copy-folders-icon.svg`;
const CopyFoldersSelected = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/copy-folders-selected.svg`;
const CopyFoldersWhite = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/copy-folders-white.svg`;
const HomeIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/home.svg`;
const InviteMembersIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/invite-members.svg`;

const Menu = (props) => {
  const { store, dispatch } = useContext(Context);
  const location = useLocation();
  const history = useHistory();

  const [hoveringEditCommunity, setHoveringEditCommunity] = useState(false);
  const [hoveringEdit, setHoveringEdit] = useState(false);
  const [hoveringCopy, setHoveringCopy] = useState(false);
  const [selected, setSelected] = useState(false);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [editingFolders, setEditingFolders] = useState(false);
  const [copyingFolders, setCopyingFolders] = useState(false);

  // Default communities
  const validDeeplink = store.deeplink !== undefined && store.deeplink.params !== undefined;

  const defaultCommunity = validDeeplink ? store.deeplink.params.communityId : undefined;
  const defaultCommunityLoaded = defaultCommunity && store.deeplink.loaded.communities;

  // Default groups
  const defaultGroup = validDeeplink ? store.deeplink.params.groupId : undefined;
  const defaultGroupLoaded = defaultGroup && store.deeplink.loaded.groups;

  // Default spaces
  const defaultSpace = validDeeplink ? store.deeplink.params.spaceId : undefined;
  const defaultSpaceLoaded = defaultSpace && store.deeplink.loaded.spaces;

  // Default spaces
  const defaultAsset = validDeeplink ? store.deeplink.params.assetId : undefined;
  const defaultAssetLoaded = defaultAsset && store.deeplink.loaded.assets;

  const fetching = useRef(false);
  const [fetched, setFetched] = useState(false);
  const communitiesCache = useRef([]);

  // Loading of params
  const paramCommunity = useRef(false);
  const paramGroup = useRef(false);
  const paramSpace = useRef(false);
  const paramAsset = useRef(false);
  const loadingGid = useRef(null);

  const copyButton = useRef(null);

  const currentCommunity = useRef(null);
  const communitiesLoaded = useRef(false);

  const sendMessage = useCallback((data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }, [dispatch, store.status]);

  // DEEPLINK ONLY: Only used for deeplinking [should mirror updates]
  const loadSpace = useCallback(async (space, cacheStore) => {
    props.setLoadingDeeplink(true);

    try {
      const data = {
        uuid: (cacheStore ?? store).profile.uuid,
        cid: (cacheStore ?? store).status.community.cid,
        gid: (cacheStore ?? store).status.group.gid,
        sid: space.sid,
        sessionId: (cacheStore ?? store).session.sessionId
      }
      const url = `${constants.services.url.api}/asset/fetch/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok) {
        // Add to URL Route
        history.push(`/g/${(cacheStore ?? store).status.community.cid}/${(cacheStore ?? store).status.group.gid}/${space.sid}/`);
        paramGroup.current = true;
        props.setLoadingDeeplink(false);

        // Add to DataStore
        let storeCopy = {
          ...(cacheStore ?? store),
          assets: {
            data: responseData.data,
            fetched: true
          },
          status: {
            ...(cacheStore ?? store).status,
            space: space
          },
          deeplink: {
            ...(cacheStore ?? store).deeplink,
            loaded: {
              ...(cacheStore ?? store).deeplink.loaded,
              assets: true
            }
          }
        }

        if (!defaultAssetLoaded && !paramAsset.current) {
          const aid = cacheStore.deeplink.params.assetId;
          const assetIndex = responseData.data.responses.map((asset) => asset.aid).indexOf(aid);

          if (assetIndex > -1) {
            storeCopy.status.asset = responseData.data.responses[assetIndex]
          }
        }

        dispatch({
          type: 'store',
          data: storeCopy
        });
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }, [dispatch, history, sendMessage, store, defaultAssetLoaded, props]);

  const loadGroup = useCallback(async (group, cacheStore) => {
    paramGroup.current = true;

    loadingGid.current = group.gid;
    setLoadingGroup(true);

    try {
      const data = {
        uuid: (cacheStore ?? store).profile.uuid,
        cid: (cacheStore ?? store).status.community.cid,
        gid: group.gid,
        sessionId: (cacheStore ? cacheStore : store).session.sessionId
      }
      const url = `${constants.services.url.api}/space/fetch/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      setLoadingGroup(false);

      if (response.ok) {
        // Add to URL Route
        history.push(`/g/${store.status.community.cid}/${group.gid}/`);

        // Store in DataStore
        let storeCopy = {
          ...(cacheStore ?? store),
          groups: {
            data: (cacheStore ?? store).groups.data,
            fetched: true
          },
          spaces: {
            data: responseData.data,
            fetched: true
          },
          assets: {
            data: {},
            fetched: false
          },
          status: {
            ...(cacheStore ?? store).status,
            group: group,
            modal: {
              active: false,
              action: null,
              type: null,
              store: null,
              thread: null,
              data: {
                title: null,
                description: null,
                inputTitle: null,
                placeholder: null,
                privacySetting: false
              }
            }
          },
          deeplink: {
            ...(cacheStore ?? store).deeplink,
            loaded: {
              ...(cacheStore ?? store).deeplink.loaded,
              spaces: true
            }
          }
        }

        if (cacheStore.deeplink.params.spaceId && cacheStore.deeplink.params.spaceId === 'create') {
          storeCopy.status.modal = {
            active: true,
            action: 'create',
            type: 'space',
            store: 'spaces',
            thread: null,
            data: {
              title: strings.default[store.language].Content.CreateNewPrompt,
              inputTitle: strings.default[store.language].Content.CreateSpaceName,
              placeholder: strings.default[store.language].Content.CreateSpacePlaceholder,
              privacySetting: false
            }
          }
        }

        if (props.creatorStudio) {
          storeCopy.status.modal = {
            active: true,
            action: 'create',
            type: 'space',
            store: 'spaces',
            thread: null,
            data: {
              title: store.status.modal.data.title,
              inputTitle: store.status.modal.data.inputTitle,
              privacySetting: false,
              magicAI: store.status.modal.data.magicAI,
              creatorStudio: true
            }
          }
        }

        dispatch({
          type: 'store',
          data: storeCopy
        });

        if (!defaultSpaceLoaded && !paramSpace.current) {
          const sid = cacheStore.deeplink.params.spaceId;
          const spaceIndex = responseData.data.map((space) => space.sid).indexOf(sid);

          if (spaceIndex > -1) {
            loadSpace(responseData.data[spaceIndex], storeCopy);
          }
        }
      } else {
        //sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      //sendMessage(constants.strings.messages('error', 'network'));
    }
  }, [dispatch, history, /*sendMessage,*/ store, defaultSpaceLoaded, loadSpace, props]);

  // Moved higher to be defined for deeplinking
  const loadCommunity = useCallback(async (event) => {
    const community = (event.override ? event.community : communitiesCache.current[event.target.selectedIndex - 1]);
    const cid = (event.override ? event.cid : event.target.options[event.target.selectedIndex].value);
    paramCommunity.current = true;

    try {
      const data = {
        uuid: store.profile.uuid,
        cid: cid,
        sessionId: store.session.sessionId
      }
      const url = `${constants.services.url.api}/group/fetch/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok) {
        // Add to URL Route
        history.push(`/g/${cid}/`);

        currentCommunity.current = community;

        // Store in DataStore
        const storeCopy = {
          ...store,
          groups: {
            data: responseData.data,
            fetched: true
          },
          status: {
            ...store.status,
            community: community
          },
          deeplink: {
            ...store.deeplink,
            loaded: {
              ...store.deeplink.loaded,
              groups: true
            }
          }
        }
        dispatch({
          type: 'store',
          data: storeCopy
        });

        if (!defaultGroupLoaded && !paramGroup.current) {
          const gid = store.deeplink.params.groupId;
          const groupIndex = responseData.data.map((group) => group.gid).indexOf(gid);

          if (groupIndex > -1) {
            loadGroup(responseData.data[groupIndex], storeCopy);
          }
        }
      } else {
        //sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      //sendMessage(constants.strings.messages('error', 'network'));
    }
  }, [dispatch, history, /*sendMessage,*/ store, defaultGroupLoaded, loadGroup]);

  const fetchCommunities = useCallback(async () => {
    fetching.current = true;

    try {
      const data = {
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId
      }
      const url = `${constants.services.url.api}/community/fetch/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok) {
        setTimeout(() => {
          dispatch({
            type: 'store',
            data: {
              ...store,
              communities: {
                data: responseData.data,
                fetched: true
              },
              deeplink: {
                ...store.deeplink,
                loaded: {
                  ...store.deeplink.loaded,
                  communities: true
                }
              }
            }
          });
          if (!communitiesCache.current.length || responseData.data.length > communitiesCache.current.length - 1) {
            communitiesCache.current = responseData.data;
          }
          fetching.current = false;
          setFetched(true);
        }, 0);

        if (!defaultCommunityLoaded && !paramCommunity.current) {
          const cid = store.deeplink.params.communityId;
          const communityIndex = responseData.data.map((community) => community.cid).indexOf(cid);

          // Send index +1 to account for 'Communities' in dropdown
          if (communityIndex > -1) {
            loadCommunity({
              override: true,
              community: responseData.data[communityIndex],
              cid: cid
            })
          }
        }
      } else {
        //sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      //sendMessage(constants.strings.messages('error', 'network'));
    }
  }, [/*sendMessage,*/ dispatch, store, defaultCommunityLoaded, loadCommunity]);

  const addCommunity = () => {
    const allowAdding = store.subscription.active ||
      (!store.subscription.active &&
        (constants.utils.isObject(store.status.community) && !Object.keys(store.status.community).length) && !store.communities.length)

    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'create',
        type: 'community',
        store: 'communities',
        thread: null,
        data: {
          title: strings.default[store.language].Menu.AddCommunity,
          description: strings.default[store.language].Menu[`AddCommunity${allowAdding ? 'Description' : 'NotAllowed'}`],
          inputTitle: strings.default[store.language].Menu.AddJoinCode,
          placeholder: strings.default[store.language].Menu.AddCommunityPlaceholder,
          privacySetting: false,
          fromCreatorStudio: props.creatorStudio !== undefined,
          magicAI: props.magicAI !== undefined && props.magicAI
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const editCommunity = () => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'edit',
        type: 'community',
        store: 'community',
        thread: null,
        data: {
          title: strings.default[store.language].Menu.EditCommunities,
          description: strings.default[store.language].Menu.EditCommunitiesDescription,
          communities: communitiesCache.current
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  /*const addJoinCode = () => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'join',
        type: 'community',
        store: 'communities',
        thread: null,
        data: {
          title: strings.default[store.language].Menu.AddJoinCode,
          description: strings.default[store.language].Menu.AddJoinCodeDescription,
          inputTitle: strings.default[store.language].Menu.AddJoinCodeName,
          placeholder: strings.default[store.language].Menu.AddJoinCodePlaceholder,
          privacySetting: false
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }*/

  const addGroup = () => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'create',
        type: 'group',
        store: 'groups',
        thread: null,
        data: {
          title: strings.default[store.language].Menu.CreateFolder,
          description: strings.default[store.language].Menu.CreateFolderDescription,
          inputTitle: strings.default[store.language].Menu.CreateFolderName,
          placeholder: strings.default[store.language].Menu.CreateFolderPlaceholder,
          privacySetting: true,
          fromCreatorStudio: props.creatorStudio !== undefined,
          magicAI: props.magicAI !== undefined && props.magicAI
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const deleteFolder = async (event, group) => {
    // TO-DO: Create message drop down

    if (store.status.modal.active) {
      try {
        let data = {
          uuid: store.profile.uuid,
          gid: group.gid,
          title: group.title,
          sessionId: store.session.sessionId
        }
        const url = `${constants.services.url.api}/group/delete/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        if (response.ok) {
          if (responseData.status === 'Success') {
            let groupsCopy = [...store.groups.data];
            const targetIndex = groupsCopy.map((group) => group.gid).indexOf(group.gid);
            if (targetIndex > -1) {
              groupsCopy.splice(targetIndex, 1);
            }

            const statusCopy = {
              ...store.status,
              modal: {
                active: false,
                action: null,
                type: null,
                store: null,
                thread: null,
                data: {
                  title: null,
                  description: null,
                  inputTitle: null,
                  placeholder: null,
                  privacySetting: false
                }
              }
            }

            if (props.creatorStudio !== undefined) {
              statusCopy.modal = {
                active: true,
                action: 'create',
                type: 'space',
                store: 'spaces',
                thread: null,
                data: {
                  title: store.status.modal.data.title,
                  inputTitle: store.status.modal.data.inputTitle,
                  privacySetting: false,
                  magicAI: store.status.modal.data.magicAI,
                  creatorStudio: true
                }
              }
            }

            const storeCopy = {
              ...store,
              groups: {
                ...store.status.groups,
                data: groupsCopy
              },
              status: statusCopy
            }

            dispatch({
              type: 'store',
              data: storeCopy
            });
          } else {
            //sendMessage(constants.strings.messages('error', 'network'));
          }
        } else {
          //sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        //sendMessage(constants.strings.messages('error', 'network'));
      }
    } else {
      event.persist();
      const statusCopy = {
        ...store.status,
        modal: {
          active: true,
          action: 'delete',
          type: 'confirm',
          store: '',
          thread: null,
          event: event,
          data: {
            title: strings.default[store.language].Menu.ConfirmDelete,
            description: strings.default[store.language].Menu.ConfirmDeleteDescription,
            inputTitle: group.title,
            placeholder: group.gid,
            privacySetting: true,
            fromCreatorStudio: props.creatorStudio !== undefined,
            magicAI: props.magicAI !== undefined && props.magicAI
          }
        }
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }
  }

  const copyFolder = async (event, group) => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'copy',
        type: 'Copy',
        store: '',
        thread: null,
        event: copyButton,
        data: {
          title: strings.default[store.language].Menu.ConfirmDuplicate,
          description: strings.default[store.language].Menu.ConfirmDuplicateDescription,
          inputTitle: group.title,
          gid: group.gid,
          privacySetting: true,
          fromCreatorStudio: props.creatorStudio !== undefined,
          magicAI: props.magicAI !== undefined && props.magicAI
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const goHome = () => {
    if (location.pathname !== '/dashboard/') {
      history.push('/dashboard/');
      window.location.reload();
    }
  }

  const triggerAddMembers = () => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'add',
        type: 'members',
        store: 'members',
        thread: null,
        data: {
          title: strings.default[store.language].Menu.InviteMembers,
          description: strings.default[store.language].Menu.InviteMembersDescription,
          placeholder: strings.default[store.language].Menu.InviteMembersPlaceholder,
          privacySetting: true
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const editFoldersManager = () => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'edit',
        type: 'folder',
        store: 'folder',
        thread: null,
        data: {
          title: strings.default[store.language].Menu.EditFolders,
          description: strings.default[store.language].Menu.EditFoldersDescription,
          folders: store.groups.data
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  useEffect(() => {
    if (store.reload.communities !== null && store.reload.communities.loaded !== undefined && !store.reload.communities.loaded && !communitiesLoaded.current) {
      if (store.reload.communities.modified !== undefined && store.reload.communities.modified) {
        communitiesCache.current = store.reload.communities.data;
        communitiesLoaded.current = true;

        setTimeout(() => {
          const reloadCopy = {
            ...store.reload,
            communities: {
              ...store.reload.communities,
              loaded: true,
              modified: false
            }
          }
          dispatch({
            type: 'reload',
            data: reloadCopy
          });
        }, 0);
      } else {
        const newCommunities = communitiesCache.current.concat(store.reload.communities.data).sort((a, b) => a.title.localeCompare(b.title));
        const finalCommunities = newCommunities.filter((obj, index, self) =>
          index === self.findIndex((t) => (
            t.title === obj.title
          ))
        );

        communitiesCache.current = finalCommunities;
        communitiesLoaded.current = true;

        setTimeout(() => {
          const storeCopy = {
            ...store,
            status: {
              ...store.status,
              modal: {
                active: true,
                action: 'actionSuccess',
                data: {
                  title: `${store.reload.communities.title}`,
                  type: 'community',
                }
              }
            },
            reload: {
              ...store.reload,
              communities: {
                ...store.reload.communities,
                loaded: true
              }
            }
          }
          dispatch({
            type: 'store',
            data: storeCopy
          });
        }, 0);
      }
    }
    if (!fetching.current && !store.communities.fetched && !communitiesCache.current.length) {
      fetchCommunities();
    }
    if (store.status.community !== currentCommunity.current) {
      currentCommunity.current = store.status.community;
    }
    if ((props.top || props.bottom) && !currentCommunity.current.length) {
      communitiesCache.current = store.communities.data;
      setFetched(true);
    }
    if (store.communities.fetched && communitiesCache.current.length && !store.groups.data.length) {
      setSelected(true);
    }
  }, [fetchCommunities, store, defaultCommunityLoaded, dispatch, loadCommunity, props.top, props.bottom]);

  return (
    <div className={`Menu${store.status.menu ? props.collapsed ? ' collapsed' : '' : ' expanded'}${props.creatorStudio ? ' creatorStudio' : ''}`}>
      <div className={`menuHeader${props.bottom ? ' hidden' : ''}`}>
        <div className="content">
          {(communitiesCache.current.length && fetched) ?
            <select
              name="communitySelect"
              className="selections"
              onChange={(event) => loadCommunity(event)}
              defaultValue={store.status.community.cid !== undefined ? store.status.community.cid : defaultCommunity}
              value={store.status.community.cid !== undefined ? store.status.community.cid : ''}>
              <option disabled={constants.utils.isObject(store.status.community) || store.deeplink?.params?.communityId !== undefined}>
                {strings.default[store.language].Menu.Communities}
              </option> ?
              {communitiesCache.current.map((community, i) => {
                return (
                  <option
                    className={`community${currentCommunity.current.title === community.title ? ' selected' : ''} ${currentCommunity.current}`}
                    key={`CommunityOption${i}`}
                    index={i}
                    value={community.cid}>
                    {community.title}
                  </option>
                )
              })}
            </select> :
            <div className="emptyCommunities">
              No Communities
            </div>
          }
        </div>
        <div className="editCommunity">
          {fetched ?
            communitiesCache.current.length ?
              <button
                className="button"
                onMouseEnter={() => setHoveringEditCommunity(true)}
                onMouseLeave={() => setHoveringEditCommunity(false)}
                onClick={() => editCommunity()}>
                <img
                  className="editIcon"
                  src={hoveringEditCommunity ? EditFoldersSelected : EditFolders}
                  alt="Edit Folders" />
              </button> : ''
            : <Loading active={true} />
          }
        </div>
        <div className="collapseExpand">
          <button
            className="button"
            onClick={() => addCommunity()}>
            +
          </button>
        </div>
      </div>
      <div className={`menuContent${props.top ? ' hidden' : ''}`}>
        {constants.utils.isObject(store.status.community) ?
          <div className="groupWrapper">
            <div className={`groupTitle${selected ? store.groups.data.length ? ' active' : ' empty' : ''}${props.bottom ? ' invisible' : ''}`}>
              <div className="title">
                <span className="iconImage">
                  <img
                    className="icon"
                    src={FolderIcon}
                    alt="Folder" />
                </span>{strings.default[store.language].Menu.Folders}
              </div>
            </div>
            {(store.groups.data.length && selected) ?
              <button
                ref={copyButton}
                className="copyFolders"
                onMouseEnter={() => setHoveringCopy(true)}
                onMouseLeave={() => setHoveringCopy(false)}
                onClick={() => {
                  setCopyingFolders(!copyingFolders);
                  setEditingFolders(false);
                }}>
                <img
                  className="editIcon"
                  src={(copyingFolders || (!copyingFolders && hoveringCopy) ? CopyFoldersSelected : CopyFoldersIcon)}
                  alt="Copy Folder" />
              </button> : ''
            }
            {(store.groups.data.length && selected) ?
              <button
                className="editFolders"
                onMouseEnter={() => setHoveringEdit(true)}
                onMouseLeave={() => setHoveringEdit(false)}
                onClick={() => editFoldersManager()}>
                <img
                  className="editIcon"
                  src={(editingFolders || (!editingFolders && hoveringEdit)) ? EditFoldersSelected : EditFolders}
                  alt="Edit Folders" />
              </button> :
              ''
            }
            {selected ?
              <button
                className={`CreateFolder${store.groups.data.length ? '' : ' empty'}`}
                onClick={() => addGroup()}>
                +
              </button> : ''}
          </div> :
          ''
        }
        <div className="content">
          {store.groups.data.map((group, i) => (
            <div
              className={`groupItem${(store.status.group.gid === group.gid) ? ' selected' : ''}`}
              key={`GroupItem${i}`}>
              <div
                className={`groupButton${(!loadingGroup && (loadingGid.current === group.gid || store.status.group.gid === group.gid)) ? ' selected' : ''}${loadingGroup || editingFolders ? ' disabled' : ''}`}
                onClick={() => (loadingGroup || editingFolders || copyingFolders ? '' : loadGroup(group, store))}>
                {/*<div className="private">
                  {group.private ? '' : ''}
                </div>*/}

                <Loading active={loadingGroup && loadingGid.current === group.gid} />

                {(!loadingGroup && !editingFolders && !copyingFolders && (loadingGid.current === group.gid || store.status.group.gid === group.gid)) &&
                  <div className="selected" />
                }
                {(editingFolders && store.profile.uuid === group.creator) &&
                  <button
                    className="deleteFolder"
                    onClick={(event) => deleteFolder(event, group)}>
                    <img
                      className="deleteIcon"
                      src={(store.status.group.gid === group.gid) ? DeleteIconWhite : DeleteIcon}
                      alt="Delete" />
                  </button>
                }
                {(copyingFolders && store.profile.uuid === group.creator) &&
                  <button
                    className="copyFolder"
                    onClick={(event) => copyFolder(event, group)}>
                    <img
                      className="copyIcon copy"
                      src={(store.status.group.gid === group.gid) ? CopyFoldersWhite : CopyFolders}
                      alt="Copy" />
                  </button>
                }
                <div className={`title${(editingFolders || copyingFolders) ? ((store.profile.uuid === group.creator) ? '' : ' hot') : ''}`}>
                  {group.title}
                </div>
              </div>
            </div>
          ))}
          {!store.groups.data.length &&
            <div className="noGroups">
              <img
                className={`noFolders${constants.utils.isObject(store.status.community) ? '' : ' emptyCommunity'}`}
                src={Arrow}
                alt="Arrow pointing to create" />
              <div className="imageWrapper">
                <img
                  className="empty"
                  src={constants.utils.isObject(store.status.community) ? EmptyFolders : EmptyCommunity}
                  alt="No groups" />
              </div>
              <div className="emptyText">
                {constants.utils.isObject(store.status.community) ? strings.default[store.language].Menu.CreateFolderStart : `${communitiesCache.current.length ? strings.default[store.language].Menu.Select : strings.default[store.language].Menu.Create} ${strings.default[store.language].Menu.CommunityBegin}`}
              </div>
            </div>
          }
        </div>
      </div>
      {!props.creatorStudio &&
        <div className="contextArea">
          <button
            className={`menuBottomButton goHome ${store.status.menu ? constants.utils.isMobile() ? 'collapsed' : 'expanded' : constants.utils.isMobile() ? 'expanded' : 'collapsed'}`}
            onClick={() => goHome()}>
            <img
              className="goHomeIcon"
              src={HomeIcon}
              alt="Go Home" />
          </button>
          {constants.utils.isObject(store.status.community) ?
            <>
              <button
                className="menuBottomButton expandCollapse"
                onClick={() => triggerAddMembers()}>
                <img
                  className="inviteMembersIcon"
                  src={InviteMembersIcon}
                  alt="Invite Members" />
              </button>
              <div className="code">
                <div className="codeText">
                  {strings.default[store.language].Content.JoinCode}:
                </div>
                <div className="codeCode">
                  {store.status.community.code}
                </div>
              </div>
            </> : ''
          }
        </div>
      }
    </div>
  );
}

export default Menu;
