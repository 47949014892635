import ReactGA from 'react-ga4';

import { useContext, useState, useRef } from 'react';
import { Context } from '../../DataStore';
import { decode } from 'html-entities';
import { useHistory } from 'react-router-dom';

import DatePicker from "react-datepicker";

import Recorder from '../recorder/Recorder';

import Loading from './Loading';
import Upload from './Upload';
import Switch from './Switch';
import Youtube from './Youtube';
import Menu from '../composite/Menu';

import InputEdit from './InputEdit';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/common/Modal.scss';
import "react-datepicker/dist/react-datepicker.css";

const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;
const ConfettiIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/confetti.svg`;
const GreenCheckmark = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/green-checkmark.svg`;
const DocumentIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/document.svg`;
const YoutubeIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/youtube.svg`;
const ArticlesIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/articles.svg`;
const PrivateOnIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/private-on.svg`;
const FigmaIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/figma.svg`;
const GdriveIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gdrive.svg`;
const CanvaIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/canva.svg`;

const NarchivesIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}png/naarchives-logo.png`;

const Modal = () => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();
  //const [isPrivate, setIsPrivate] = useState(false);
  const [titleValue, setTitleValue] = useState('');

  const handleValue = useRef('');

  const [descriptionValue, setDescriptionValue] = useState('');
  const [success, setSuccess] = useState(false);
  const [attachments, setAttachments] = useState(false);

  const [enableYoutube, setEnableYoutube] = useState(false);
  const [youtube, setYoutube] = useState('');
  const [youtubeValidLink, setYoutubeValidLink] = useState(false);
  const [youtubeID, setYoutubeID] = useState('');
  const [previewYoutube, setPreviewYoutube] = useState(false);

  const [enableNarchives, setEnableNarchives] = useState(false);
  const [narchives, setNarchives] = useState('');
  const [narchivesValidLink, setNarchivesValidLink] = useState(false);
  const [previewNarchives, setPreviewNarchives] = useState(false);
  const [narchivesVideo, setNarchivesVideo] = useState('');

  const [enableFigJam, setEnableFigJam] = useState(false);
  const [figJam, setFigJam] = useState('');
  const [figJamValidLink, setFigJamValidLink] = useState(false);
  const [previewFigJam, setPreviewFigJam] = useState(false);

  const [enableGdrive, setEnableGdrive] = useState(false);
  const [gdrive, setGdrive] = useState('');
  const [gdriveValidLink, setGdriveValidLink] = useState(false);
  const [previewGdrive, setPreviewGdrive] = useState(false);

  const [enableCanva, setEnableCanva] = useState(false);
  const [canva, setCanva] = useState('');
  const [canvaValidLink, setCanvaValidLink] = useState(false);
  const [previewCanva, setPreviewCanva] = useState(false);

  const [educator, setEducator] = useState(false);

  // Video Uploading
  const [cameraStarted, setCameraStarted] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [recorderSuccess, setRecorderSuccess] = useState(false);
  const recordType = useRef('');
  const recorderRef = useRef();

  const buttonAccept = useRef(null);

  // Modal types
  const recorderModal = (store.status.modal.type === 'space' || store.status.modal.type === 'asset');
  const isMagic = (store.status.modal.data.magicAI !== undefined && store.status.modal.data.magicAI);
  const isSpace = store.status.modal.type === 'space';
  const isAsset = store.status.modal.type === 'asset';
  const isConfirm = store.status.modal.type === 'confirm';

  const isThread = store.status.modal.thread !== null;

  // Creator Studio
  const creatorStudio = (store.status.modal.data.creatorStudio !== undefined && store.status.modal.data.creatorStudio);
  const isResponse = (store.status.modal.data.response !== undefined && store.status.modal.data.response);
  const isQuickResponse = (isResponse && (store.status.space.magic === undefined && !store.status.space.magic));

  // Premium Features
  const [generator, setGenerator] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [aiSubject, setAiSubject] = useState('');
  const [aiAspects, setAiAspects] = useState('');
  const [scriptVisible, setScriptVisible] = useState(true);

  const aiTextArea = useRef(null);

  // Join community states
  const isAddCommunity = store.status.modal.data.title === strings.default[store.language].Menu.AddCommunity;
  const [communitySelected, setCommunitySelected] = useState(isAddCommunity ? 'code' : false);

  // Modal titles
  const isAddJoinCode = store.status.modal.data.title === strings.default[store.language].Menu.AddJoinCode || communitySelected === 'code';
  const isAddFolder = store.status.modal.data.title === strings.default[store.language].Menu.CreateFolder;
  const isMirror = store.status.modal.data.title === 'Check Mirror';

  const isDeleteGroup = store.status.modal.action === 'delete';
  const isEditProfile = store.status.modal.action === 'editProfile';
  const isYoutubeAttachment = store.status.modal.action === 'youtubeAttachment';
  const isNarchivesAttachment = store.status.modal.action === 'narchivesAttachment';
  const isCopyFolder = store.status.modal.action === 'copy';
  const isPremium = store.status.modal.action === 'premium';

  const isKeyword = store.status.modal.action === 'keyword';
  const isEditKeywords = store.status.modal.action === 'editKeywords';

  const isActionSuccess = store.status.modal.action === 'actionSuccess';
  const isCommunityCreateSuccess = isActionSuccess && store.status.modal.data.type === 'community';

  const isEditingCommunities = store.status.modal.action === 'edit' && store.status.modal.type === 'community';
  const isEditingFolders = store.status.modal.action === 'edit' && store.status.modal.type === 'folder';
  const isAddMembers = store.status.modal.action === 'add' && store.status.modal.type === 'members';

  const isPromptSettings = store.status.modal.action === 'settings';

  const [keywords, setKeywords] = useState([]);
  const [keywordsControl, setKeywordsControl] = useState([]);
  const [keywordsChanged, setKeywordsChanged] = useState(false);

  // File Upload
  const [fileType, setFileType] = useState(null);
  const [extension, setExtension] = useState(null);
  const [upload, setUpload] = useState(null);
  const [selected, setSelected] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(DefaultAvatar);

  const imageSelector = useRef(null);

  // Transcript
  const [finalTranscript, setFinalTranscript] = useState('');
  const [finalKeywords, setFinalKeywords] = useState('');

  // Image
  //const [uploadedImage, setUploadedImage] = useState('');
  const [imageChanged, setImageChanged] = useState(false);

  // Private
  const [privateOn, setPrivateOn] = useState(false);

  // Calendar
  const [publishDate, setPublishDate] = useState((isPromptSettings && store.status.settings.creation !== undefined) ? new Date(store.status.settings.creation) : new Date());

  const fetchedMembers = useRef(false);
  const [notifyAll, setNotifyAll] = useState(true);
  const [notifying, setNotifying] = useState(false);

  const [emailing, setEmailing] = useState(false);
  const [emailed, setEmailed] = useState(false);

  // Settings
  const [changingSettingTranscript, setChangingSettingTranscript] = useState(false);
  const [changingSettingTranslate, setChangingSettingTranslate] = useState(false);
  const [changingSettingCreation, setChangingSettingCreation] = useState(false);

  const sendMessage = (data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  };

  const closeModal = (data, storeReplace) => {
    if (history.location.pathname === '/dashboard/premium/') {
      history.push('/dashboard/');
    }

    if (recorderModal && cameraStarted) {
      recorderRef.current.closeRecorder();
    }

    const targetStore = (storeReplace !== null && storeReplace !== undefined) ? storeReplace : store;

    const storeCopy = {
      ...targetStore,
      profile: {
        ...targetStore.profile,
        image: data !== undefined ? data : targetStore.profile.image
      },
      status: {
        ...targetStore.status,
        modal: {
          active: false,
          action: null,
          type: null,
          store: null,
          thread: null,
          data: {
            title: null,
            description: null,
            inputTitle: null,
            placeholder: null,
            privacySetting: false
          }
        }
      },
      reload: {
        ...store.reload,
        header: isPremium ? null : data,
        communities: isActionSuccess ? null : store.reload.communities
      }
    }

    if (store.status.modal.data.fromCreatorStudio !== undefined && store.status.modal.data.fromCreatorStudio) {
      storeCopy.status.modal = {
        active: true,
        action: 'create',
        type: 'space',
        store: 'spaces',
        thread: null,
        data: {
          title: strings.default[store.language].Dashboard[store.status.modal.data.magicAI ? 'MagicAIPrompt' : 'QuickPrompt'],
          inputTitle: strings.default[store.language].Content.CreateSpaceName,
          privacySetting: false,
          magicAI: store.status.modal.data.magicAI,
          creatorStudio: true
        }
      }
    }

    dispatch({
      type: 'store',
      data: storeCopy
    });
  }

  // Submit final upload of media to server
  const submitRequest = async () => {
    //setUploading(true);

    try {
      let data = {
        cid: (isAddCommunity && communitySelected === 'new') ? null : constants.utils.cleanText(titleValue),
        uuid: store.profile.uuid,
        title: titleValue,
        sessionId: store.session.sessionId
      }

      if (store.status.modal.type === 'group') {
        data.cid = store.status.community.cid
      }

      const url = `${constants.services.url.api}/${store.status.modal.type}/${(isAddCommunity && isAddJoinCode) ? 'join' : store.status.modal.action}/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();
      setUploading(false);

      if (response.ok) {
        if (responseData.status === 'Success') {
          const newReturnedData = responseData[store.status.modal.type];
          setTitleValue('');

          let newData = [
            ...store[store.status.modal.store].data,
            newReturnedData
          ].sort((a, b) => a.title.localeCompare(b.title));

          const storeCopy = {
            ...store,
            [store.status.modal.store]: {
              ...store[store.status.modal.store],
              data: newData
            }
          }

          // Auto select it as part
          if (store.status.modal.data.fromCreatorStudio !== undefined && store.status.modal.data.fromCreatorStudio) {
            storeCopy.status = {
              ...storeCopy.status,
              modal: {
                active: true,
                action: 'create',
                type: 'space',
                store: 'spaces',
                thread: null,
                data: {
                  title: strings.default[store.language].Dashboard[store.status.modal.data.magicAI ? 'MagicAIPrompt' : 'QuickPrompt'],
                  inputTitle: strings.default[store.language].Content.CreateSpaceName,
                  privacySetting: false,
                  magicAI: store.status.modal.data.magicAI,
                  creatorStudio: true
                }
              }
            }
          }
          if (isAddCommunity || isAddJoinCode) {
            storeCopy.status = {
              ...storeCopy.status,
              modal: {
                active: false,
                action: null,
                type: null,
                store: null,
                thread: null,
                data: {
                  title: null,
                  description: null,
                  inputTitle: null,
                  placeholder: null,
                  privacySetting: false
                }
              }
            }
            storeCopy.reload = {
              ...storeCopy.reload,
              communities: {
                data: newData,
                title: titleValue,
                loaded: false
              }
            }
          }
          dispatch({
            type: 'store',
            data: storeCopy
          });

          if (store.status.modal.data.fromCreatorStudio === undefined) {
            buttonAccept.current.innerHTML = 'Added!';
            setSuccess(true);
            setTimeout(() => {
              closeModal(null, storeCopy);
            }, 1000);
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      setUploading(false);
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  // Upload new video to s3 API
  const submitMedia = async () => {
    if (attachments && upload === null) {
      // TO-DO: integrate Message component
      alert('Please select a file to attach');
    } else {
      setUploading(true);

      let data = {
        cid: store.status.community.cid,
        gid: store.status.group.gid,
        sid: null,
        uuid: store.profile.uuid,
        description: null,
        type: recordType.current,
        sessionId: store.session.sessionId,
        relation: {},
        links: [], // TO-DO: v1 - Leaving it out / replace with collaborative notes
        title: null,
        spaceType: null,
        transcript: store.status.modal.data.magicAI ? finalTranscript : '',
        keywords: finalKeywords,
        youtube: youtubeID,
        narchives: narchivesVideo,
        figjam: figJam,
        gdrive: gdrive,
        canva: canva,
        extension: constants.utils.isSafari() ? 'mp4' : 'webm',
        magic: store.status.modal.data.magicAI,
        premium: store.subscription.active,
        private: privateOn,
        publishDate: publishDate
      }

      switch (store.status.modal.type) {
        case 'space':
          data.title = titleValue;
          data.description = descriptionValue;
          data.spaceType = /*type*/'conversation';
          break;
        case 'asset':
          data.sid = store.status.space.sid;
          data.description = titleValue;
          data.relation = {
            space: store.status.space.sid,
            response: store.status.space.sid,
            thread: isThread ? store.status.modal.thread : null
          }
          break;
        default:
          break;
      }

      const date = Date().toLocaleString([], {
        month: '2-digit',
        year: '4-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
      const dateArr = date.split(' (');
      const name = dateArr[0];

      const superBuffer = new Blob(recordedBlobs, { type: constants.utils.isSafari() ? 'video/mp4; codecs=avc1' : 'video/webm; codecs=avc1' });
      const fileType = new File([superBuffer], `${name}.${constants.utils.isSafari() ? 'mp4' : 'webm'}`, { type: constants.utils.isSafari() ? 'video/mp4; codecs=avc1' : 'video/webm; codecs=avc1' });

      let file = new FormData();
      file.append('data', fileType);
      file.append('metadata', JSON.stringify(data));

      try {
        const config = {
          method: 'POST',
          body: file
        }
        const url = `${constants.services.url.api}/${store.status.modal.type}/create/`;
        const response = await fetch(url, config);
        const responseData = await response.json();

        if (response.ok) {
          if (responseData.status === 'Success' || responseData.status === 'Processing') {
            const isProcessing = responseData.status === 'Processing';

            // Complete upload after attachment upload
            const completeUpload = (files) => {
              setUploading(false);
              setCameraStarted(false);
              setRecorderSuccess(true);

              if (isAsset) {
                let storeCopy = { ...store }

                let spacesCopy = [...store.spaces.data];
                const spaceIndex = spacesCopy.map(space => space.aid).indexOf(store.status.space.aid);

                if (spaceIndex > -1 && spacesCopy[spaceIndex].creator.uuid !== store.profile.uuid) {
                  const participantIndex = spacesCopy[spaceIndex].participants.map(participant => participant.name).indexOf(store.profile.name);
                  if (participantIndex < 0) {
                    spacesCopy[spaceIndex].participants.push({
                      image: store.profile.image,
                      name: store.profile.name
                    });
                    storeCopy = {
                      ...store,
                      spaces: {
                        ...store.spaces,
                        data: spacesCopy
                      }
                    }
                  }
                }

                if (isThread) {
                  let responsesCopy = [...store.assets.data.responses];
                  const responseIndex = responsesCopy.map(response => response.aid).indexOf(store.status.asset.aid);

                  const responseDataCreator = {
                    ...responseData.data,
                    creation: new Date().toISOString(),
                    creator: store.profile,
                    data: {
                      ...responseData.data.data,
                      location: isProcessing ? window.URL.createObjectURL(superBuffer) : responseData.data.data.location
                    },
                    votes: [],
                    reactions: [],
                    processing: isProcessing
                  }

                  if (responseIndex > -1) {
                    responsesCopy[responseIndex].thread.push(responseDataCreator);
                  }

                  storeCopy = {
                    ...storeCopy,
                    assets: {
                      ...storeCopy.assets,
                      data: {
                        ...storeCopy.assets.data,
                        responses: responsesCopy
                      }
                    },
                    status: {
                      ...store.status,
                      asset: {
                        ...store.status.asset,
                        thread: [
                          ...store.status.asset.thread
                        ]
                      }
                    }
                  }
                } else {
                  storeCopy = {
                    ...storeCopy,
                    assets: {
                      ...storeCopy.assets,
                      data: {
                        ...storeCopy.assets.data,
                        responses: [
                          ...storeCopy.assets.data.responses,
                          {
                            ...responseData.data,
                            creation: new Date().toISOString(),
                            creator: store.profile,
                            data: {
                              ...responseData.data.data,
                              location: isProcessing ? window.URL.createObjectURL(superBuffer) : responseData.data.data.location
                            },
                            votes: [],
                            reactions: [],
                            thread: [],
                            processing: isProcessing
                          }
                        ]
                      }
                    }
                  }
                }
                dispatch({
                  type: 'store',
                  data: storeCopy
                });

              } else if (isSpace) {
                dispatch({
                  type: 'store',
                  data: {
                    ...store,
                    spaces: {
                      ...store.spaces,
                      data: [
                        ...store.spaces.data,
                        {
                          ...responseData.data,
                          creator: store.profile,
                          participants: [],
                          files: files
                        }
                      ],
                      fetched: true
                    },
                    subscription: {
                      ...store.subscription,
                      tokens: (!store.subscription.active && store.status.modal.data.magicAI ? store.subscription.tokens - 1 : store.subscription.tokens)
                    }
                  }
                });
              }
            }

            if (attachments) {
              // Upload single file to AWS and MongoDB
              const data = {
                uuid: store.profile.uuid,
                aid: responseData.data.aid,
                type: extension,
                sessionId: store.session.sessionId
              }

              let file = new FormData();
              file.append('data', upload);
              file.append('metadata', JSON.stringify(data));

              try {
                const config = {
                  method: 'POST',
                  body: file
                }
                const url = `${constants.services.url.api}/asset/create/file/`;
                const response = await fetch(url, config);
                const responseData = await response.json();

                if (response.ok) {
                  if (responseData.status === 'Success') {
                    completeUpload([responseData.data]);
                  } else {
                    sendMessage({ type: 'error', text: responseData.status });
                  }
                } else {
                  sendMessage(constants.strings.messages('error', 'network'));
                }
              } catch (error) {
                sendMessage(constants.strings.messages('error', 'network'));
              }
            } else {
              completeUpload([]);
            }
          } else {
            sendMessage({ type: 'error', text: responseData.status });
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    }
  }

  const configureAttachment = () => {
    if (attachments) {
      setFileType(null);
      setExtension(null);
      setUpload(null);
      setSelected(false);
    }

    setAttachments(!attachments);
  }

  const configureYoutube = () => {
    setEnableYoutube(!enableYoutube);
    setYoutube('');
    setYoutubeValidLink(false);
    setYoutubeID('');
    setPreviewYoutube(false);
  }

  const configureNarchives = () => {
    setEnableNarchives(!enableNarchives);
    setNarchives('');
    setNarchivesValidLink(false);
    setNarchivesVideo('');
    setPreviewNarchives(false);
  }

  const configureFigJam = () => {
    setEnableFigJam(!enableFigJam);
    setFigJam('');
  }

  const configureGdrive = () => {
    setEnableGdrive(!enableGdrive);
    setGdrive('');
  }

  const configureCanva = () => {
    setEnableCanva(!enableCanva);
    setCanva('');
  }

  const setFileTypeConfig = (type) => {
    setExtension(type);
  }

  const fileSelected = (fileData) => {
    setUploadedImage(URL.createObjectURL(fileData));
    const fileNameArr = fileData.name.split('.');
    const fileType = fileNameArr[fileNameArr.length - 1];
    setExtension(fileType);
    setImageChanged(true);
    setUpload(fileData);
    //onImageEdit('image', fileData)
  }

  /*const resetImage = () => {
    setUploadedImage(DefaultAvatar);
    setImageChanged(false);
    //onImageEdit('image', 'default')
  }*/

  const uploadProfile = () => {
    const sendData = async (config, type) => {
      try {
        const url = `${constants.services.url.api}/user/edit/${type}/`;
        const response = await fetch(url, config);
        const responseData = await response.json();

        setUploading(false);

        if (response.ok) {
          if (responseData.message === 'Success') {
            setTimeout(() => {
              setImageChanged(false);
              setUploading(false);
              closeModal(responseData.data.image);
              //sendMessage(constants.strings.messages('success', 'editProfile'));
            }, 0)
          } else {
            sendMessage(constants.strings.messages('error', 'editProfile'));
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        setUploading(false);
        sendMessage(constants.strings.messages('error', 'network'));
      }
    }

    let profileData = {
      uuid: store.profile.uuid,
      name: (titleValue.length ? titleValue : store.profile.name),
      handle: (handleValue.current.length ? `@${handleValue.current.replace('@', '').replace(' ', '')}` : store.profile.handle),
      image: (store.profile.image === 'default' ? '' : store.profile.image),
      extension: extension,
      sessionId: store.session.sessionId
    }

    if (imageChanged) {
      let file = new FormData();
      file.append('data', upload);
      file.append('metadata', JSON.stringify(profileData));

      /*if (profileData.image === 'default') {
        sendData(constants.services.config(profileData), 'profile');
      } else {
      let profileCopy = {...profileData}

      if (!file.has('metadata')) {
        profileCopy.image.append('metadata', JSON.stringify(profileCopy));
      }*/
      const config = {
        method: 'POST',
        body: file,
      }
      sendData(config, 'file');
      //}
    } else {
      sendData(constants.services.config(profileData), 'profile');
    }
  }

  const buttonDisableControl = () => {
    const titleValueClean = constants.utils.cleanText(titleValue);

    const addCommunityCondition = (isAddCommunity && (communitySelected === 'code' ? titleValueClean.length < 6 : !titleValueClean.length));
    const addJoinCodeCondition = (isAddJoinCode && titleValueClean.length < 6);
    const addFolderCondition = (isAddFolder && !titleValueClean.length);
    const isSpaceCondition = (isSpace ? !descriptionValue.length : false);
    const recorderModalCondition = (recorderModal ? (!recordedBlobs.length || !titleValueClean.length) : false);

    const editNameInvalid = (store.status.modal.data.value === titleValueClean || !titleValueClean.length);
    const handleNameInvalid = (store.profile.handle.replace('@', '') === handleValue.current || !handleValue.current.length);
    const isEditProfileCondition = (isEditProfile ? editNameInvalid && handleNameInvalid && !imageChanged : false);
    const isCopyFolderCondition = (isCopyFolder && (store.status.modal.data.inputTitle === titleValueClean || !titleValueClean.length));
    const keywordsNotUpdated = (isEditKeywords && !keywordsChanged);

    const addMemberCondition = isAddMembers && (!constants.validate.email(titleValue) || emailing);

    return (
      success ||
      addCommunityCondition ||
      addJoinCodeCondition ||
      addFolderCondition ||
      isSpaceCondition ||
      recorderModalCondition ||
      isEditProfileCondition ||
      isCopyFolderCondition ||
      keywordsNotUpdated ||
      addMemberCondition
    )
  }

  const submitFolder = async () => {
    try {
      let data = {
        uuid: store.profile.uuid,
        cid: store.status.community.cid,
        gid: store.status.modal.data.gid,
        title: titleValue,
        sessionId: store.session.sessionId
      }
      const url = `${constants.services.url.api}/group/duplicate/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok) {
        if (responseData.status === 'Success') {

          if (store.status.modal.data.fromCreatorStudio === undefined) {
            store.status.modal.event?.current.click();
          }

          const newReturnedData = responseData.group;

          let newGroupsData = [
            ...store.groups.data,
            newReturnedData
          ].sort((a, b) => a.title.localeCompare(b.title));

          const groupsCopy = {
            ...store.groups,
            data: newGroupsData
          }

          const statusCopy = {
            ...store.status,
            modal: {
              active: false,
              action: null,
              type: null,
              store: null,
              thread: null,
              data: {
                title: null,
                description: null,
                inputTitle: null,
                placeholder: null,
                privacySetting: false
              }
            }
          }

          if (store.status.modal.data.fromCreatorStudio !== undefined && store.status.modal.data.fromCreatorStudio) {
            statusCopy.modal = {
              active: true,
              action: 'create',
              type: 'space',
              store: 'spaces',
              thread: null,
              data: {
                title: strings.default[store.language].Dashboard[store.status.modal.data.magicAI ? 'MagicAIPrompt' : 'QuickPrompt'],
                inputTitle: strings.default[store.language].Content.CreateSpaceName,
                privacySetting: false,
                magicAI: store.status.modal.data.magicAI,
                creatorStudio: true
              }
            }
          }

          const storeCopy = {
            ...store,
            groups: groupsCopy,
            status: statusCopy
          }

          dispatch({
            type: 'store',
            data: storeCopy
          });
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  const validateServiceLink = (service, link) => {
    let regexPattern = null;
    switch (service) {
      case 'youtube':
        regexPattern = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        break;
      case 'narchives':
        regexPattern = /catalog\.archives\.gov\/id\/(\d+)/;
        break;
      case 'figjam':
        regexPattern = /https:\/\/[\w.-]+\.?figma.com\/([\w-]+)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;
        break;
      case 'gdrive':
        regexPattern = /^https:\/\/docs\.google\.com\//;
        break;
      case 'canva':
        regexPattern = /^https:\/\/[\w.-]+\.?canva.com\//;
        break;
      default:
        return false;
    }

    if (link) {
      if (link.match(regexPattern)) {
        return true;
      }
    }
    return false;
  }

  const getYoutubeID = (link) => {
    setPreviewYoutube(false);

    if (validateServiceLink('youtube', link)) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = link.match(regExp);
      const finalID = (match && match[2].length === 11 ? match[2] : undefined);
      setYoutubeValidLink(finalID !== undefined);
      setYoutubeID(finalID !== undefined ? finalID : '');
    } else {
      setYoutubeValidLink(false);
      setYoutubeID('');
    }
  }

  const getNarchivesID = async (link) => {
    setPreviewNarchives(false);

    if (validateServiceLink('narchives', link)) {
      const regExp = /catalog\.archives\.gov\/id\/(\d+)/;
      const match = link.match(regExp);
      const finalID = match ? match[1] : null;

      if (finalID !== null) {
        let data = {
          url: link,
        }

        const url = `${constants.services.url.api}/narchives/video/src/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        if (response.ok && responseData.status === 'Success') {
          setNarchivesValidLink(finalID !== undefined);
          setNarchivesVideo(responseData.url);
        } else {
          setNarchivesValidLink(false);
          setNarchivesVideo('');
        }
      } else {
        setNarchivesValidLink(false);
        setNarchivesVideo('');
      }
    } else {
      setNarchivesValidLink(false);
      setNarchivesVideo('');
    }
  }

  const getFigJamID = (link) => {
    setPreviewFigJam(false);
    setFigJamValidLink(validateServiceLink('figjam', link));
  }

  const getGdriveID = (link) => {
    setPreviewGdrive(false);
    setGdriveValidLink(validateServiceLink('gdrive', link));
  }

  const getCanvaID = (link) => {
    setPreviewCanva(false);
    setCanvaValidLink(validateServiceLink('canva', link));
  }

  const upgradePremium = async (duration, tier) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: `Stripe Premium - ${duration} ${tier} - ${store.profile.uuid}`
    });

    const data = {
      uuid: store.profile.uuid,
      product: 'gravity',
      duration: duration,
      tier: tier,
      educator: educator
    }

    const url = `${constants.services.url.api}/payments/create-checkout-session/`;
    const response = await fetch(url, constants.services.config(data));
    const responseData = await response.json();

    if (responseData.redirect) {
      window.location.href = responseData.redirect;
    } else {
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  const openEmail = () => {
    window.location.href = 'mailto:hello@usegravity.io';
  }

  const generationManager = () => {
    setGenerator(!generator);
  }

  const promptGenerate = async () => {
    /*ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: `AI Prompt Generate - ${store.subscription.active ? 'Premium' : 'Free'} - ${store.profile.uuid}`
    });*/

    setGenerating(true);

    const data = {
      uuid: store.profile.uuid,
      tokens: 75,
      subject: aiSubject,
      aspects: aiAspects,
      sessionId: store.session.sessionId
    }

    const url = `${constants.services.url.api}/authenticity/ai/lesson/`;
    const response = await fetch(url, constants.services.config(data));
    const responseData = await response.json();

    setGenerating(false);
    if (responseData.status === 'Success') {
      setTitleValue(responseData.title !== undefined ? responseData.title.replace(/["']/g, "") : titleValue);
      setDescriptionValue(responseData.description !== undefined ? responseData.description : descriptionValue);
      aiTextArea.current.value = responseData.script !== undefined ? responseData.script.replace(/Discussion Prompt: \n\n/g, '').replace('Prompt: ', '') : aiTextArea.current.value;
    } else {
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  const editKeyword = (event, i) => {
    const newKeywordValue = event.target.value;

    let keywordsCopy = [...keywords];
    keywordsCopy[i] = newKeywordValue;

    setKeywords(keywordsCopy);
    setKeywordsChanged(!constants.utils.arraysAreEqual(keywordsCopy, keywordsControl));
  }

  if (keywords.length === 0 && isEditKeywords) {
    const cleanUpArray = (arr) => {
      return arr.map(item => {
        return constants.utils.cleanText(item);
      });
    }

    const keywordsCopy = cleanUpArray([...store.status.modal.data.value.split(',')]);
    setKeywords(keywordsCopy);
    setKeywordsControl(keywordsCopy);
  }

  const updateKeywords = async () => {
    setUploading(true);

    const data = {
      uuid: store.profile.uuid,
      aid: store.status.modal.data.aid,
      sessionId: store.session.sessionId,
      keywords: keywords.join(', ')
    }

    try {
      const url = `${constants.services.url.api}/asset/keywords/update/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok) {
        if (responseData.status === 'Success') {
          let storeMain = { ...store }

          switch (store.status.modal.data.assetType) {
            case 'main':
              const assetsCopy = {
                ...store.assets,
                data: {
                  ...store.assets.data,
                  data: {
                    ...store.assets.data.data,
                    keywords: data.keywords
                  }
                }
              }
              dispatch({
                type: 'assets',
                data: assetsCopy
              });

              storeMain = {
                ...storeMain,
                assets: assetsCopy
              }

              break;
            case 'response':
            case 'threadMain':
              let responsesCopy = [...store.assets.data.responses];
              const responseIndex = responsesCopy.map(response => response.aid).indexOf(store.status.modal.data.aid);

              if (responseIndex > -1) {
                responsesCopy[responseIndex] = {
                  ...responsesCopy[responseIndex],
                  data: {
                    ...responsesCopy[responseIndex].data,
                    keywords: data.keywords
                  }
                }

                let storeCopy = {
                  ...store,
                  assets: {
                    ...store.assets,
                    data: {
                      ...store.assets.data,
                      responses: responsesCopy
                    }
                  }
                }

                if (store.status.modal.data.assetType === 'threadMain') {
                  storeCopy.status.asset = {
                    ...storeCopy.status.asset,
                    data: {
                      ...storeCopy.status.asset.data,
                      keywords: data.keywords
                    }
                  }
                }

                dispatch({
                  type: 'store',
                  data: storeCopy
                });

                storeMain = storeCopy;
              }
              break;
            case 'thread':
              let assetThreadCopy = [...store.assets.data.responses[store.status.modal.data.responseIndex].thread];
              const assetThreadIndex = assetThreadCopy.map(thread => thread.aid).indexOf(store.status.modal.data.aid);

              if (assetThreadIndex > -1) {
                assetThreadCopy[assetThreadIndex] = {
                  ...assetThreadCopy[assetThreadIndex],
                  data: {
                    ...assetThreadCopy[assetThreadIndex].data,
                    keywords: data.keywords
                  }
                }
              }

              const statusCopy = {
                ...store.status,
                asset: {
                  ...store.status.asset,
                  thread: assetThreadCopy
                },
                thread: assetThreadCopy
              }

              dispatch({
                type: 'status',
                data: statusCopy
              });

              storeMain = {
                ...storeMain,
                status: statusCopy
              }

              break;
            default:
              break;
          }
          setTimeout(() => {
            setUploading(false);
            closeModal(storeMain.profile.image, storeMain);
          }, 500);
        } else {
          sendMessage({ type: 'error', text: responseData.status });
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  const fetchMembers = async () => {
    let data = {
      cid: store.status.community.cid,
      uuid: store.profile.uuid,
      sessionId: store.session.sessionId
    }

    const url = `${constants.services.url.api}/community/fetch/members/`;
    const response = await fetch(url, constants.services.config(data));
    const responseData = await response.json();

    if (responseData.status === 'Success') {
      const statusCopy = {
        ...store.status,
        members: responseData.data
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }
  }

  if ((recorderSuccess || isActionSuccess) && !fetchedMembers.current) {
    fetchedMembers.current = true;
    fetchMembers();
  }

  const sendNotifications = async () => {
    setNotifying(true);

    const latestAsset = store.spaces.data[store.spaces.data.length - 1];

    const members = store.status.members.map(user => ({
      name: user.name,
      email: user.email
    }));

    let data = {
      url: `/g/${latestAsset.cid}/${latestAsset.gid}/${latestAsset.sid}/`,
      title: latestAsset.metadata.title,
      group: store.status.group.title,
      community: store.status.community.title,
      members: members,
      name: store.profile.name,
      uuid: store.profile.uuid,
      sessionId: store.session.sessionId
    }

    const url = `${constants.services.url.api}/manage/notifications/discussion/`;
    await fetch(url, constants.services.config(data));

    setNotifying(false);
    closeModal();
  }

  const modificationManager = (action, data, value) => {
    let dataCopy = [...(isEditingCommunities ? store.status.modal.data.communities : store.groups.data)];
    const dataIndex = dataCopy.map(dataMap => dataMap[isEditingCommunities ? 'cid' : 'gid']).indexOf(data);

    if (dataIndex > -1) {
      if (action === 'delete') {
        dataCopy.splice(dataIndex, 1);
      } else if (action === 'save') {
        dataCopy[dataIndex].title = value;
      }

      const storeCopy = {
        ...store,
        status: {
          ...store.status,
          modal: {
            ...store.status.modal,
            data: {
              ...store.status.modal.data,
              [isEditingCommunities ? 'communities' : 'folders']: dataCopy
            }
          }
        }
      }

      if (isEditingCommunities) {
        storeCopy.communities = {
          ...store.communities,
          data: dataCopy
        }
        storeCopy.reload = {
          ...store.reload,
          communities: {
            data: dataCopy,
            loaded: false,
            modified: true
          }
        }
      } else {
        storeCopy.groups = {
          ...store.groups,
          data: dataCopy
        }
      }

      dispatch({
        type: 'store',
        data: storeCopy
      });
    }
  }

  const addMember = async () => {
    setEmailing(true);

    let data = {
      code: store.status.community.code,
      title: store.status.community.title,
      name: store.profile.name,
      email: constants.utils.cleanText(titleValue),
      uuid: store.profile.uuid,
      sessionId: store.session.sessionId
    }

    const url = `${constants.services.url.api}/manage/community/invite/`;
    await fetch(url, constants.services.config(data));

    setEmailed(true);
    setTimeout(() => {
      setTitleValue('');
      setEmailing(false);
      setEmailed(false);
    }, 1000);
  }

  const setSetting = async (setting, value) => {
    switch (setting) {
      case 'transcript': setChangingSettingTranscript(true); break;
      case 'translate': setChangingSettingTranslate(true); break;
      case 'creation': setChangingSettingCreation(true); break;
      default: break;
    }

    const stopLoading = () => {
      switch (setting) {
        case 'transcript': setChangingSettingTranscript(false); break;
        case 'translate': setChangingSettingTranslate(false); break;
        case 'creation': setChangingSettingCreation(false); break;
        default: break;
      }
    }

    try {
      let data = {
        sid: store.status.space.sid,
        value: value,
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId
      }

      const url = `${constants.services.url.api}/space/settings/modify/${setting}/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      stopLoading();

      if (response.ok) {
        if (responseData.status === 'Success') {
          const newCreation = responseData.data.creation;

          const spacesCopy = [...store.spaces.data];
          const spacesIndex = spacesCopy.map((space) => space.sid).indexOf(store.status.space.sid);
          if (spacesIndex > -1) spacesCopy[spacesIndex].creation = newCreation;

          const storeCopy = {
            ...store,
            assets: {
              ...store.assets,
              data: {
                ...store.assets.data,
                creation: newCreation
              }
            },
            spaces: {
              ...store.spaces,
              data: spacesCopy
            },
            status: {
              ...store.status,
              settings: responseData.data,
              space: {
                ...store.status.space,
                creation: newCreation
              }
            }
          }
          dispatch({
            type: 'store',
            data: storeCopy
          });
        }
      }
    } catch (error) {
      stopLoading();
    }
  }

  const setPremium = () => {
    closeModal();
    setTimeout(() => {
      history.push('/dashboard/premium/');

      const statusCopy = {
        ...store.status,
        modal: {
          active: true,
          action: 'premium',
          data: {
            title: store.subscription.active ? strings.default[store.language].Modal.YouArePremium : strings.default[store.language].Modal.PremiumTitle,
            description: store.subscription.active ? `${store.profile.name.split(' ')[0]}, ${strings.default[store.language].Modal.PremiumThankYou} ${strings.default[store.language].Modal.PremiumMember} (${strings.default[store.language].Modal[store.subscription.duration]})!` : strings.default[store.language].Modal.PremiumDescription
          }
        }
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }, 500);
  }

  const renderSubscription = () => (
    <div className="subscriptionButtonsWrapper">
      <div className="subscriptionEduCheck">
        <label className="subscriptionLabel">
          {strings.default[store.language].Modal.IAmEducator}
        </label>
        <input
          type="checkbox"
          className="subscriptionCheckbox"
          onChange={() => setEducator(!educator)}
          checked={educator} />
      </div>
      <div className="subscriptionButtons">
        <button
          ref={buttonAccept}
          className="submitRequest"
          disabled={buttonDisableControl()}
          onClick={() => upgradePremium('monthly', 'basic')}>
          {strings.default[store.language].Modal[`Upgrade${educator ? 'Educator' : ''}`]}
        </button>
        {educator &&
          <div className="magicCount">
            <div className="magicNumber">
              {strings.default[store.language].Modal.EducatorDiscount}
            </div>
          </div>
        }
      </div>
      <div className="subscriptionButtons">
        <button
          ref={buttonAccept}
          className="submitRequest yearly"
          disabled={buttonDisableControl()}
          onClick={() => upgradePremium('yearly', 'basic')}>
          {strings.default[store.language].Modal[`UpgradeYearly${educator ? 'Educator' : ''}`]}
        </button>
        <div className="magicCount">
          <div className="magicNumber">
            {strings.default[store.language].Modal[`Savings${educator ? 'Educator' : ''}`]}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className={`Modal${recorderModal ? ' recorderModal' : ''}`}>
      <div className={`modalWrapper${isYoutubeAttachment || isNarchivesAttachment ? ' youtube' : ''}${(recorderModal && !store.status.modal.data.magicAI) ? ' quick' : ''}${isActionSuccess ? ' actionSuccess' : ''}`}>
        {(isPremium && store.subscription.active) &&
          <div className="successBackground" />
        }
        <div className="modalTitle">
          {!isActionSuccess &&
            <button
              className="closeModal"
              onClick={() => closeModal()}>
              {strings.default[store.language].Modal.Close}
            </button>
          }
          {isAsset &&
            <div className="avatarResponse">
              <div
                className="avatar"
                style={{ backgroundImage: `url(${store.status.space.creator.image !== undefined && store.status.space.creator.image !== '' ? store.status.space.creator.image : DefaultAvatar})` }} />
            </div>
          }
          {isMagic &&
            <div className="magicAIOrb" />
          }
          {!isActionSuccess &&
            <div className={`title${isMagic ? ' magic' : ''}`}>
              {store.status.modal.data.title}
            </div>
          }
        </div>

        {recorderSuccess || isActionSuccess ?
          <div className="successWrapper">
            <div className={`successBackground${isActionSuccess ? ' actionSuccess' : ''}`} />
            <div className="successContent">
              <div className="confetti">
                <img
                  className="confettiIcon"
                  src={ConfettiIcon}
                  alt="confetti" />
              </div>
              <div className="headline">
                {isActionSuccess ? store.status.modal.data.title : `${strings.default[store.language].Modal.Yay} ${strings.default[store.language].Modal[(isSpace ? 'Prompt' : 'Response')]} ${strings.default[store.language].Modal.Created}`}
              </div>
              <div className="text">
                {strings.default[store.language].Modal.OnYourWay}
              </div>
              {/*!isCommunityCreateSuccess &&
                <div className="notification">
                  <input
                    type="checkbox"
                    className="notificationCheckbox"
                    disabled={notifying}
                    onChange={() => setNotifyAll(!notifyAll)}
                    checked={notifyAll} />
                  <div className="notificationQuestion">
                    {`${strings.default[store.language].Modal.NotifyAll} ${store.status.members.length} ${strings.default[store.language].Modal.MembersOf} ${store.status.community.title}`}
                  </div>
                </div>
              */}

              <div className="buttonWrapper">
                <button
                  className="successClose"
                  disabled={notifying}
                  onClick={() => /*notifyAll && !isCommunityCreateSuccess ? sendNotifications() :*/ closeModal()}>
                  {strings.default[store.language].Modal[/*notifyAll && !isCommunityCreateSuccess ? 'Notify' :*/ 'Return']}
                </button>
                {notifying &&
                  <Loading active="true" />
                }
              </div>
            </div>
          </div> :
          creatorStudio && (!(constants.utils.isObject(store.status.community) && constants.utils.isObject(store.status.group))) ?
            <div className="creatorSelection">
              <div className="selectionFlow">
                <div className="flowTitle">
                  <span className="colorFlow">Step 1:</span> Select Community
                  <img
                    className={`selectionOk${constants.utils.isObject(store.status.community) ? ' valid' : ''}`}
                    src={GreenCheckmark}
                    alt="Selection OK" />
                </div>
                <Menu
                  creatorStudio
                  magicAI={store.status.modal.data.magicAI}
                  top />
                <div className="flowTitle bottom">
                  <span className="colorFlow">Step 2:</span> Select Folder
                  <img
                    className={`selectionOk${constants.utils.isObject(store.status.group) ? ' valid' : ''}`}
                    src={GreenCheckmark}
                    alt="Selection OK" />
                </div>
                <Menu
                  creatorStudio
                  magicAI={store.status.modal.data.magicAI}
                  bottom />
              </div>
            </div> :
            <div className="modalContent">
              {(isSpace && isMagic) &&
                <>
                  <button
                    className="promptGenerate"
                    onClick={() => generationManager()}>
                    <div className="generateTitle">
                      {strings.default[store.language].Modal.AIPromptAssistant}
                    </div>
                    <div className="generateWrapper">
                      <div className={`generateDot${generating ? ' active' : generator ? ' active' : ''}`} />
                      {generating &&
                        <Loading active="true" />
                      }
                    </div>
                  </button>
                  {generator &&
                    <div className="generator">
                      <div className="inputWrapper">
                        <input
                          className="aiField"
                          type="text"
                          placeholder={'Main topic'}
                          value={aiSubject}
                          onChange={(event) => setAiSubject(event.target.value)} />
                      </div>
                      <div className="inputWrapper">
                        <input
                          className="aiField aspects"
                          type="text"
                          placeholder={'Aspects to cover (Comma-separated)'}
                          value={aiAspects}
                          onChange={(event) => setAiAspects(event.target.value)} />
                      </div>
                      <div className="buttonWrapper">
                        <button
                          className="generateButton"
                          disabled={generating || aiSubject.length < 1 || aiAspects.length < 1}
                          onClick={() => promptGenerate()}>
                          Generate
                        </button>
                      </div>
                    </div>
                  }
                  <div className={`aiScript${scriptVisible ? '' : ' hidden'}`}>
                    <button
                      className="toggleScript"
                      onClick={() => setScriptVisible(!scriptVisible)}>
                      {strings.default[store.language].Modal[scriptVisible ? 'Hide' : 'Show']}
                    </button>
                    <div className="script">
                      {strings.default[store.language].Modal.PromptScript}
                    </div>
                    <textarea
                      ref={aiTextArea}
                      className="aiText"
                      contentEditable="true"
                      placeholder={strings.default[store.language].Modal.PromptScriptPlaceholder} />
                  </div>
                </>
              }

              {isKeyword &&
                <div className="keywordTitle">
                  <div className="keywordAI">
                    AI
                  </div>
                  <div className="keywordWord">
                    {`${strings.default[store.language].AssetsView.Insights}`}
                    <span className="beta">
                      BETA
                    </span>
                  </div>
                </div>
              }
              {store.status.modal.data.spaceTitle &&
                <div className="description">
                  <span className="bolded">{store.status.modal.data.spaceTitle}</span>
                </div>
              }
              {store.status.modal.data.description &&
                <div className={`description${isKeyword ? ' keyword' : ''}`}>
                  <span className="bolded">{isCopyFolder ? `${store.status.modal.data.inputTitle} ` : ''}</span> {store.status.modal.data.description} <span className="bolded">{(isSpace || isEditProfile || isAddCommunity || isAddJoinCode || isAddFolder || isAsset || isCopyFolder) ? '' : store.status.modal.data.inputTitle}{store.status.modal.data.type !== undefined && store.status.modal.data.type === 'member' ? `${store.status.modal.data.description2}?` : ''}</span>
                </div>
              }
              {(!isDeleteGroup && !isEditProfile && !isMirror && !isKeyword && !isPremium && !isEditingCommunities && !isEditingFolders && !isPromptSettings) &&
                <div className={`entryWrapper${recorderModal ? ' recorderEntry' : ''}`}>
                  {isAddCommunity &&
                    <div className="addTypeToggle">
                      <div className="addToggle">
                        <button
                          className={`toggleType${communitySelected === 'code' ? ' selected' : ''}`}
                          onClick={() => setCommunitySelected('code')}>
                          {strings.default[store.language].Modal.JoinByCode}
                        </button>
                      </div>
                      <div className="orToggle">
                        {strings.default[store.language].Upload.Or}
                      </div>
                      <div className="addToggle">
                        {store.subscription.active ||
                          (!store.subscription.active &&
                            (constants.utils.isObject(store.status.community) && !Object.keys(store.status.community).length) && !store.communities.length) ?
                          <button
                            className={`toggleType${communitySelected === 'new' ? ' selected' : ''}`}
                            onClick={() => setCommunitySelected('new')}>
                            {strings.default[store.language].Modal.CreateNew}
                          </button> :
                          <button
                            className="toggleType premium"
                            onClick={() => setPremium()}>
                            ✨ {store.subscription.active ? 'Gravity' : strings.default[store.language].Dashboard.Unlock} <span className="premium">{strings.default[store.language].Package.Premium}</span>
                          </button>
                        }
                      </div>
                    </div>
                  }
                  {isYoutubeAttachment ?
                    <Youtube
                      width="670"
                      height="380"
                      youtubeID={store.status.modal.data.value} /> :
                    isNarchivesAttachment ?
                      <video
                        className="narchivesVideo"
                        width="670"
                        height="380"
                        src={store.status.modal.data.value}
                        playsInline
                        controls /> :
                      <>
                        <div className="inputWrapper">
                          {isEditKeywords && keywords.length ?
                            keywords.map((keyword, i) => (
                              <div
                                className="keywordMap"
                                key={`KeywordEdit${i}`}>
                                <input
                                  className={`input${isAddJoinCode ? ' joinCode' : ''} ${recorderModal ? 'recorder' : 'centered'}${isResponse ? ' response' : ''}`}
                                  type="text"
                                  onChange={(event) => editKeyword(event, i)}
                                  defaultValue={constants.utils.cleanText(keyword)} />
                              </div>
                            )) :
                            <input
                              className={`input${isAddJoinCode ? ' joinCode' : ''} ${recorderModal ? 'recorder' : 'centered'}${isResponse ? ' response' : ''}`}
                              type="text"
                              placeholder={(communitySelected === 'code' || isAddMembers) ? store.status.modal.data.placeholder : isCopyFolder ? '' : isAddCommunity ? strings.default[store.language].Menu.CreateCommunityPlaceholder : store.status.modal.data.inputTitle}
                              disabled={emailing}
                              value={titleValue}
                              onChange={(event) => setTitleValue(communitySelected === 'code' ? event.target.value.toUpperCase() : event.target.value)}
                              maxLength={isAddJoinCode ? 6 : ''} />
                          }
                        </div>
                      </>
                  }
                </div>
              }

              {(isEditingCommunities || isEditingFolders) &&
                <div className="communityWrapper">
                  {store.status.modal.data[isEditingCommunities ? 'communities' : 'folders'].map((data, i) => {
                    return (
                      data.creator === store.profile.uuid ?
                        <div
                          className="eligibleEdit"
                          key={`Edit${i}`}>
                          <InputEdit
                            value={data.title}
                            data={{
                              id: data[isEditingCommunities ? 'cid' : 'gid'],
                              type: isEditingCommunities ? 'community' : 'group'
                            }}
                            modification={(action, data, value) => modificationManager(action, data, value)} />
                        </div> :
                        <div className="ineligibleEdit">
                          {data.title}
                        </div>
                    )
                  })}
                </div>
              }

              {isSpace &&
                <>
                  {(!isMirror && !isKeyword) &&
                    <div className="entryWrapper recorderEntry">
                      {!recorderModal &&
                        <div className="entryTitle">
                          <span className="required">*</span> {strings.default[store.language].Modal.ShortDescription}
                        </div>
                      }
                      <div className={`inputWrapper${recorderModal ? ' recorder' : ''}`}>
                        <input
                          className={`input description${recorderModal ? ' recorder' : ''}`}
                          type="text"
                          placeholder={strings.default[store.language].Modal.ShortDescription}
                          value={descriptionValue}
                          onChange={(event) => setDescriptionValue(event.target.value)} />
                      </div>
                    </div>
                  }
                </>
              }

              {recorderModal &&
                <>
                  <Recorder
                    ref={recorderRef}
                    cameraStarted={cameraStarted}
                    uploading={uploading}
                    recordType={recordType}
                    recorderSuccess={recorderSuccess}
                    setRecordedBlobs={(blobs) => setRecordedBlobs(blobs)}
                    setCameraStarted={(started) => setCameraStarted(started)}
                    setRecordType={(type) => recordType.current = type}
                    submitMedia={(data) => submitMedia(data)}
                    mirrorMode={isMirror}
                    sendMessage={(data) => sendMessage(data)}
                    setFinalTranscript={(transcript) => setFinalTranscript(transcript)}
                    setFinalKeywords={(keywords) => setFinalKeywords(keywords)} />
                  {(isSpace && !isMirror && isMagic) &&
                    <div className="uploadSelectionWrapper">
                      <div className="uploadSelection">
                        <div className="instructions">
                          <div className="attachmentText">
                            <img src={DocumentIcon} alt="Figma logo" className="document" /> {strings.default[store.language].Modal.AttachFile}
                          </div>
                          <Switch
                            disabled={uploading}
                            boolean={attachments}
                            onClick={() => configureAttachment()} />
                        </div>
                        {attachments &&
                          <Upload
                            uploading={uploading}
                            fileType={fileType}
                            setFileType={(type) => setFileType(type)}
                            setExtension={(extension) => setFileTypeConfig(extension)}
                            upload={upload}
                            setUpload={(file) => setUpload(file)}
                            selected={selected}
                            setSelected={(state) => setSelected(state)} />
                        }
                      </div>
                      <div className="uploadSelection">
                        <div className="instructions">
                          <div className="attachmentText">
                            <img src={YoutubeIcon} alt="Youtube logo" className="youtube" /> <span>{strings.default[store.language].Modal.YoutubeLink}</span>
                          </div>
                          <Switch
                            boolean={enableYoutube}
                            onClick={() => configureYoutube()} />
                          {enableYoutube &&
                            <div className="inputWrapper">
                              <span className="required">* </span>
                              <input
                                className="input youtube"
                                type="text"
                                placeholder="ex: https://www.youtube.com/watch?v=MpU0pEx1Z1Y"
                                value={youtube}
                                onChange={(event) => {
                                  setPreviewYoutube(false);
                                  setYoutube(event.target.value);
                                  getYoutubeID(event.target.value);
                                }} />
                              <img
                                className={`youtubeOK${youtubeValidLink ? ' valid' : ''}`}
                                src={GreenCheckmark}
                                alt="URL OK" />
                              <div className="previewWrapper">
                                <button
                                  className="previewYoutube"
                                  disabled={!youtubeValidLink}
                                  onClick={() => setPreviewYoutube(!previewYoutube)}>
                                  {previewYoutube ? strings.default[store.language].Modal.ClosePreview : strings.default[store.language].Modal.ViewPreview}
                                </button>
                              </div>
                              {previewYoutube &&
                                <Youtube youtubeID={youtubeID} />
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <div className="uploadSelection">
                        <div className="instructions">
                          <div className="attachmentText">
                            <img src={NarchivesIcon} alt="National Archives logo" className="narchives" /> <span>{strings.default[store.language].Modal.NarchivesLink}</span>
                          </div>
                          <Switch
                            boolean={enableNarchives}
                            onClick={() => configureNarchives()} />
                          {enableNarchives &&
                            <div className="inputWrapper">
                              <span className="required">* </span>
                              <input
                                className="input youtube"
                                type="text"
                                placeholder="ex: https://catalog.archives.gov/id/45642166"
                                value={narchives}
                                onChange={(event) => {
                                  setPreviewNarchives(false);
                                  setNarchives(event.target.value);
                                  getNarchivesID(event.target.value);
                                }} />
                              <img
                                className={`youtubeOK${narchivesValidLink ? ' valid' : ''}`}
                                src={GreenCheckmark}
                                alt="URL OK" />
                              <div className="previewWrapper">
                                <button
                                  className="previewYoutube"
                                  disabled={!narchivesValidLink}
                                  onClick={() => setPreviewNarchives(!previewNarchives)}>
                                  {previewNarchives ? strings.default[store.language].Modal.ClosePreview : strings.default[store.language].Modal.ViewPreview}
                                </button>
                              </div>
                              {previewNarchives &&
                                <video
                                  className="narchivesPlayer"
                                  src={narchivesVideo}
                                  playsInline
                                  controls />
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <div className="uploadSelection">
                        <div className="instructions">
                          <div className="attachmentText">
                            <img src={FigmaIcon} alt="Figma logo" className="figma" /> <span>{strings.default[store.language].Modal.FigJamLink}</span>
                          </div>
                          <Switch
                            boolean={enableFigJam}
                            onClick={() => configureFigJam()} />
                          {enableFigJam &&
                            <div className="inputWrapper">
                              <span className="required">* </span>
                              <input
                                className="input figJam"
                                type="text"
                                placeholder="ex: https://www.figma.com/design/k1pQ9ADrEa..."
                                value={figJam}
                                onChange={(event) => {
                                  setPreviewFigJam(false);
                                  setFigJam(event.target.value);
                                  getFigJamID(event.target.value);
                                }} />
                              <img
                                className={`youtubeOK${figJamValidLink ? ' valid' : ''}`}
                                src={GreenCheckmark}
                                alt="URL OK" />
                              <div className="previewWrapper">
                                <button
                                  className="previewYoutube"
                                  disabled={!figJamValidLink}
                                  onClick={() => setPreviewFigJam(!previewFigJam)}>
                                  {previewFigJam ? strings.default[store.language].Modal.ClosePreview : strings.default[store.language].Modal.ViewPreview}
                                </button>
                              </div>
                              {previewFigJam &&
                                <iframe
                                  className="figmaEmbed"
                                  title="Gravity Figma Embed"
                                  src={`https://www.figma.com/embed?embed_host=Gravity
                                  &embed_origin=usegravity.io
                                  &url=${figJam}`}
                                  allowFullScreen />
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <div className="uploadSelection">
                        <div className="instructions">
                          <div className="attachmentText">
                            <img src={GdriveIcon} alt="Gdrive logo" className="gdrive" /> <span>{strings.default[store.language].Modal.GdriveLink}</span>
                          </div>
                          <Switch
                            boolean={enableGdrive}
                            onClick={() => configureGdrive()} />
                          {enableGdrive &&
                            <div className="inputWrapper">
                              <span className="required">* </span>
                              <input
                                className="input gDrive"
                                type="text"
                                placeholder="ex: https://docs.google.com/presentation/..."
                                value={gdrive.replace('pub?', 'embed?')}
                                onChange={(event) => {
                                  let eventValue = event.target.value.replace('pub?', 'embed?');
                                  setPreviewGdrive(false);
                                  setGdrive(eventValue);
                                  getGdriveID(eventValue);
                                }} />
                              <img
                                className={`youtubeOK${gdriveValidLink ? ' valid' : ''}`}
                                src={GreenCheckmark}
                                alt="URL OK" />
                              <div className="previewWrapper">
                                <button
                                  className="previewYoutube"
                                  disabled={!gdriveValidLink}
                                  onClick={() => setPreviewGdrive(!previewGdrive)}>
                                  {previewGdrive ? strings.default[store.language].Modal.ClosePreview : strings.default[store.language].Modal.ViewPreview}
                                </button>
                              </div>
                              {previewGdrive &&
                                <iframe
                                  className="gravityEmbed"
                                  title="Gravity Gdrive Embed"
                                  src={gdrive}
                                  allowFullScreen />
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <div className="uploadSelection">
                        <div className="instructions">
                          <div className="attachmentText">
                            <img src={CanvaIcon} alt="Canva logo" className="canva" /> <span>{strings.default[store.language].Modal.CanvaLink}</span>
                          </div>
                          <Switch
                            boolean={enableCanva}
                            onClick={() => configureCanva()} />
                          {enableCanva &&
                            <div className="inputWrapper">
                              <span className="required">* </span>
                              <input
                                className="input canva"
                                type="text"
                                placeholder="ex: https://www.canva.com/design/..."
                                value={canva}
                                onChange={(event) => {
                                  let eventValue = event.target.value.replace(/\/edit.*$/, '/view?embed');
                                  setPreviewCanva(false);
                                  setCanva(eventValue);
                                  getCanvaID(eventValue);
                                }} />
                              <img
                                className={`youtubeOK${canvaValidLink ? ' valid' : ''}`}
                                src={GreenCheckmark}
                                alt="URL OK" />
                              <div className="previewWrapper">
                                <button
                                  className="previewYoutube"
                                  disabled={!canvaValidLink}
                                  onClick={() => setPreviewCanva(!previewCanva)}>
                                  {previewCanva ? strings.default[store.language].Modal.ClosePreview : strings.default[store.language].Modal.ViewPreview}
                                </button>
                              </div>
                              {previewCanva &&
                                <iframe
                                  className="figmaEmbed"
                                  title="Gravity Canva Embed"
                                  loading="lazy"
                                  src={canva}
                                  allowFullScreen />
                              }
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </>
              }

              {(isAsset && !constants.utils.isObject(store.status.asset)) &&
                <div className="privateToggle">
                  <Switch
                    disabled={uploading}
                    boolean={privateOn}
                    onClick={() => setPrivateOn(!privateOn)} />
                  <div className={`toggleText${privateOn ? ' private' : ''}`}>
                    <img
                      className="privateIcon"
                      src={PrivateOnIcon}
                      alt="Private state" />
                    {strings.default[store.language].Modal.Private}
                  </div>
                </div>
              }

              {(recorderModal && (isSpace && !isMirror && isMagic)) &&
                <div className="datePickerWrapper">
                  <div className="datePickerContainer">
                    <div className="datePickerTitle">
                      {strings.default[store.language].Recorder.PublishDate}
                    </div>
                    <DatePicker
                      minDate={new Date()}
                      dayClassName={() => "datePicker"}
                      popperPlacement="top-start"
                      selected={publishDate}
                      placeholderText={"Today"}
                      onChange={(date) => setPublishDate(new Date(date).setHours(0, 0, 0, 0))} />
                  </div>
                </div>
              }

              {/*store.status.modal.data.privacySetting ?
          <div className="privateWrapper">
            <div className="makePrivate">
              Group Visibility
            </div>
            <div className="buttonWrapper">
              <button 
                className="private"
                onClick={() => setIsPrivate(!isPrivate)}> 
                {isPrivate ? 'Private' : 'Public'}
              </button>
            </div>
          </div> : 
          ''
          */}

              {isPromptSettings &&
                <div className="settingsWrapper">
                  {store.status.settings.transcript !== undefined &&
                    <div className="settingsSwitch">
                      <div className="settingType">
                        {strings.default[store.language].Modal.Transcript}
                      </div>
                      <div className="settingSwitch">
                        <Loading active={changingSettingTranscript} />
                        <Switch
                          disabled={changingSettingTranscript}
                          boolean={store.status.settings.transcript}
                          onClick={() => setSetting('transcript', !store.status.settings.transcript)} />
                      </div>
                    </div>
                  }
                  {store.status.settings.translate !== undefined &&
                    <div className="settingsSwitch">
                      <div className="settingType">
                        {strings.default[store.language].Modal.Translation}
                      </div>
                      <div className="settingSwitch">
                        <Loading active={changingSettingTranslate} />
                        <Switch
                          disabled={changingSettingTranslate}
                          boolean={store.status.settings.translate}
                          onClick={() => setSetting('translate', !store.status.settings.translate)} />
                      </div>
                    </div>
                  }
                  {store.status.settings.creation !== undefined &&
                    <div className="settingsSwitch">
                      <div className="settingType">
                        {strings.default[store.language].Recorder.PublishDate}
                      </div>
                      <div className="settingSwitch">
                        <Loading active={changingSettingCreation} />
                        <DatePicker
                          minDate={new Date()}
                          dayClassName={() => "datePicker"}
                          popperPlacement="top-start"
                          selected={publishDate}
                          placeholderText={publishDate}
                          onChange={(date) => {
                            setPublishDate(new Date(date).setHours(0, 0, 0, 0));
                            setSetting('creation', new Date(date).setHours(0, 0, 0, 0));
                          }} />
                      </div>
                    </div>
                  }
                </div>
              }

              {isEditProfile &&
                <>
                  <div className="profileEdit">
                    <div className="entryTitle">
                      {strings.default[store.language].LoginRegister.EmailAddress}
                    </div>
                    <div className="staticEntry">
                      {store.profile.email}
                    </div>
                  </div>
                  <div className="profileEdit">
                    <div className="entryTitle">
                      {store.status.modal.data.inputTitle}
                    </div>
                    <input
                      className="input"
                      type="text"
                      placeholder={store.status.modal.data.placeholder}
                      defaultValue={store.status.modal.data.value}
                      onChange={(event) => setTitleValue(event.target.value)} />
                  </div>
                  {/*<div className="profileEdit">
              <div className="entryTitle">
                {strings.default[store.language].Modal.ChangeHandle}
              </div>
              <input
                  className="input" 
                  type="text" 
                  placeholder={strings.default[store.language].Modal.ChangeHandlePlaceholder}
                  defaultValue={store.profile.handle.replace('@','')}
                  onChange={(event) => handleValue.current = event.target.value} />
              </div>*/}
                  <div className="profileEdit">
                    <div className="entryTitle">
                      {strings.default[store.language].Modal.ProfileImage}
                    </div>
                    <div className="profilePreview">
                      <div
                        className="avatar"
                        style={{ backgroundImage: `url(${imageChanged ? uploadedImage : store.profile.image !== undefined && store.profile.image !== '' ? constants.services.s3ToCloudfrontMedia(store.profile.image) : DefaultAvatar})` }} />
                    </div>
                    <button
                      className="selectImage"
                      onClick={() => imageSelector.current.click()}>
                      {strings.default[store.language].Modal.New}
                    </button>
                    <input
                      className="inputImage"
                      ref={imageSelector}
                      disabled={uploading}
                      type="file"
                      accept="image/png, image/jpeg, image/apng, image/avif, image/gif, image/svg+xml, image/webp"
                      onChange={(event) => fileSelected(event.target.files[0])} />
                  </div>
                </>
              }

              {isKeyword ?
                <>
                  <div className="context">
                    <div className="contextHeader">
                      <img
                        className="contextIcon"
                        src={YoutubeIcon}
                        alt="Youtube icon" />
                      <div className="contextName">
                        {strings.default[store.language].Modal.RelatedVideos}
                      </div>
                    </div>
                    {store.status.modal.data.youtube.map((video, i) => (
                      <button
                        key={`Youtube${i}`}
                        className={`contextWrapper ${video.id.videoId}`}
                        onClick={() => setYoutubeID((youtubeID === video.id.videoId) ? '' : video.id.videoId)}>
                        <div className="contextThumbnail">
                          <img
                            className="thumbnail"
                            src={video.snippet.thumbnails.default.url}
                            alt={decode(video.snippet.title)} />
                        </div>
                        <div className="contextTitle">
                          <div className="name">
                            {decode(video.snippet.title).length > 80 ?
                              `${decode(video.snippet.title).substring(0, 80)}...` :
                              decode(video.snippet.title)
                            }
                          </div>
                          <div className="description">
                            {decode(video.snippet.description).length > 84 ?
                              `${decode(video.snippet.description).substring(0, 84)}...` :
                              decode(video.snippet.description)
                            }
                          </div>
                        </div>
                        {youtubeID === video.id.videoId &&
                          <>
                            <div className="youtubeClose">
                              X
                            </div>
                            <div className="youtubeVideo">
                              <Youtube
                                width="540"
                                height="320"
                                youtubeID={video.id.videoId} />
                            </div>
                          </>
                        }
                      </button>
                    ))}
                  </div>
                  <div className="context">
                    <div className="contextHeader">
                      <img
                        className="contextIcon"
                        src={ArticlesIcon}
                        alt="Articles icon" />
                      <div className="contextName">
                        {strings.default[store.language].Modal.RelatedArticles}
                      </div>
                    </div>
                    {!store.status.modal.data.news.length ?
                      <div className="contextWrapper">
                        <div className="noResults">
                          {strings.default[store.language].Modal.NoResults}
                        </div>
                      </div> :
                      store.status.modal.data.news.map((news, i) => (
                        <button
                          key={`News${i}`}
                          className={`contextWrapper ${news}`}
                          onClick={() => window.open(news.url, "_blank")}>
                          <div className="contextThumbnail">
                            <img
                              className="thumbnail"
                              src={news.urlToImage ? news.urlToImage : ArticlesIcon}
                              alt={decode(news.title)} />
                          </div>
                          <div className="contextTitle">
                            <div className="name">
                              {decode(news.title).length > 80 ?
                                `${decode(news.title).substring(0, 80)}...` :
                                decode(news.title)
                              }
                            </div>
                            <div className="description">
                              {`${new Date(news.publishedAt).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$1/$2/$3')}`} - {decode(news.source.name)}
                            </div>
                          </div>
                        </button>
                      ))}
                  </div>
                </> : ''
              }

              {isPremium &&
                <>
                  {/*renderSubscription()*/}
                  <div className="premiumWrapper">
                    <div className="premium">
                      <div className="premiumTitle">
                        🪄 {strings.default[store.language].Package.UnlimitedMagicAIPrompts}
                      </div>
                      <div className="premiumDescription">
                        {strings.default[store.language].Package.UnlimitedMagicAIPromptsDescription}
                      </div>
                    </div>
                    <div className="premium">
                      <div className="premiumTitle">
                        🔮 {strings.default[store.language].Package.UnlimitedRecordingLimit}
                      </div>
                      <div className="premiumDescription">
                        {strings.default[store.language].Package.UnlimitedRecordingLimitDescription}
                      </div>
                    </div>
                    <div className="premium">
                      <div className="premiumTitle">
                        🎤 {strings.default[store.language].Package.AIVoiceToneAnalysis}
                      </div>
                      <div className="premiumDescription">
                        {strings.default[store.language].Package.AIVoiceToneAnalysisDescription}
                      </div>
                    </div>
                    <div className="premium">
                      <div className="premiumTitle">
                        ✍️ {strings.default[store.language].Package.AIAutoTranscription}
                      </div>
                      <div className="premiumDescription">
                        {strings.default[store.language].Package.AIAutoTranscriptionDescription}
                      </div>
                    </div>
                    <div className="premium">
                      <div className="premiumTitle">
                        🔎 {strings.default[store.language].Package.AIKeywordsAndInsights}
                      </div>
                      <div className="premiumDescription">
                        {strings.default[store.language].Package.AIKeywordsAndInsightsDescription}
                      </div>
                    </div>
                    <div className="premium">
                      <div className="premiumTitle">
                        🔗 {strings.default[store.language].Package.DocumentAttachments}
                      </div>
                      <div className="premiumDescription">
                        {strings.default[store.language].Package.DocumentAttachmentsDescription}
                      </div>
                    </div>
                  </div>
                </>
              }

              {isConfirm ?
                <div className="buttonAccept">
                  <div className="sideBySide">
                    <button
                      ref={buttonAccept}
                      className="submitRequest cancel"
                      onClick={() => closeModal()}>
                      {strings.default[store.language].Modal.Cancel}
                    </button>
                  </div>
                  <div className="sideBySide">
                    <div className="sideBySide">
                      <button
                        ref={buttonAccept}
                        className="submitRequest delete"
                        onClick={() => store.status.modal.event?.target.click()}>
                        {strings.default[store.language].Modal.Delete}
                      </button>
                    </div>
                  </div>
                </div> :
                (!isMirror && !isYoutubeAttachment && !isKeyword && !isEditingCommunities && !isEditingFolders && !isPromptSettings) &&
                <div className={`buttonAccept${((isAsset || isThread) && !isPremium) ? ' assetThread' : (isPremium ? ' premiumPrompt' : '')}${isQuickResponse ? ' quick' : ''}`}>
                  {isPremium ?
                    store.subscription.active ?
                      <button
                        className="contactUs"
                        onClick={() => openEmail()}>
                        {strings.default[store.language].Modal.ContactUs}
                      </button> :
                      !constants.utils.isMobile() &&
                      renderSubscription()
                    :
                    <button
                      ref={buttonAccept}
                      className={`submitRequest${success ? ' success' : ''}${uploading ? ' uploading' : ''}`}
                      disabled={buttonDisableControl()}
                      onClick={() => (recorderModal ? submitMedia() : (isEditProfile ? uploadProfile() : isCopyFolder ? submitFolder() : isPremium ? upgradePremium('monthly', 'basic') : isEditKeywords ? updateKeywords() : isAddMembers ? addMember() : submitRequest()))}>
                      <Loading active={uploading || emailing} />
                      {uploading ? strings.default[store.language].Modal.Uploading : isCopyFolder ? strings.default[store.language].Menu.Duplicate : isPremium ? strings.default[store.language].Modal.Upgrade : isEditKeywords ? strings.default[store.language].Modal.Update : isAddMembers ? (emailed ? strings.default[store.language].Menu.InviteSent : strings.default[store.language].Menu.InviteButton) : strings.default[store.language].Modal.Submit}
                    </button>
                  }
                </div>
              }
            </div>
        }
      </div>
    </div>
  );
}

export default Modal;
